<template>
<div class="s-footer" style="text-align:center">
    <a-divider />
    <div v-if="_mobile">

        <!-- Copyright © 2020, Powered by storelink. -->


            <div class="s-footer" style="min-width:300px">
                상호 : 주식회사 스토어링크 <br/>
                대표 : 정용은 <br/>
                개인정보보호책임자 : 권기인 
                <a target="_blank"  style="color:#7e7e7e" href="https://storelink.io/doc/storelink_privacypolicy.html"><u>개인정보 처리방침</u></a>&nbsp;&nbsp;<a href="https://storelink.io/doc/storelink_termsofuse.html" target="_blank"  style="color:#7e7e7e"><u>이용약관</u></a><br />

                주소 : 서울특별시 중구 중림로 50-1 스카이빌딩 9F, B1, B2 <br/>
                사업자등록번호 : 439-85-01354 <br/>
                대표 전화 : 070-4786-2112 
            </div>

    </div>
    <a-row v-else>

       <a-col :span="24">
            <div class="s-footer" style="text-align:center">
              <div>상호 : 주식회사 스토어링크 대표 : 정용은</div>
              <div>개인정보보호책임자 : 권기인 <a  style="color:#7e7e7e" target="_blank" href="https://storelink.io/doc/storelink_privacypolicy.html"><u>개인정보 처리방침</u></a>&nbsp;&nbsp;<a href="https://storelink.io/doc/storelink_termsofuse.html" target="_blank" style="color:#7e7e7e"><u>이용약관</u></a></div>

               <div>주소 : 서울특별시 중구 중림로 50-1 스카이빌딩 9F, B1, B2</div>
               <div>사업자등록번호 : 439-85-01354  </div>
                   <div>대표 전화 : 070-4786-2112</div>
            </div>

        </a-col>



    </a-row>
</div>
</template>

<script>
import { isMobile } from "../../views/components/fnc.js";

export default {
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  }
};
</script>

<style>
.s-footer {
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.52px;
  color: #7d7d7d;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  position: relative;
  bottom: 0;
}
</style>

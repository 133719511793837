<template>
<a-layout>
    <a-layout-content>
        <a-row class="target" v-bind:class="[ _mobile  ? '' : 'pc' ]">
            <a-col :span="span" :offset="offSet">
                <div style="display: table;margin: 0 auto; max-width: 394px; width: 92%;min-width: 365px;">
                    <target-navbar :step="step"></target-navbar>
                    <div style="display: table;margin: 0 auto;max-width:394px;width:100%" v-if="step==1">
                        <div class="s-target-font3" style="margin-top:34px;margin-bottom:16px">상위노출 지원 서비스 신청</div>

                        <div class="s-target-b2" v-if="!expandInfo" @click="expandInfo = true" style="cursor:pointer">
                            <div style="float:left;padding-left:10px;padding-top:8px">
                                <i class="fal fa-info-circle" style="color:#0264fb"></i>&nbsp;<span class="s-target-font2">상위노출 지원 신청 전 읽어주세요!</span>
                            </div>
                            <div style="float:right;padding-right:10px;padding-top:8px;">
                                <i class="far fa-angle-down" style="color:#0264fb"></i>
                            </div>
                        </div>
                        <div class="s-target-b2" v-if="expandInfo" @click="expandInfo = false" style="cursor:pointer">
                            <div style="float:left;padding-left:10px;padding-top:8px">
                                <i class="fal fa-info-circle" style="color:#0264fb"></i>&nbsp;<span class="s-target-font2">상위노출 지원 신청 전 읽어주세요!</span>
                                <div class="s-target-font23" style="padding-top:19px;padding-right:5px">
                                    - 스토어링크는 빈 박스만 보내는 형태는 진행하지 않습니다. <br />이는 쇼핑몰에서 알게 되면 계정 삭제 처리 등 위험 부담이 높은 작업이기 때문입니다.<br />
                                    - 그렇기 때문에 기본적으로 쇼핑몰에서 구매해야 하는 제품비용을 포함해 결제해야 합니다.<br />
                                    다만 해당 쇼핑몰에서 다시 결제를 받기 때문에 제품비용은 돌려받게 되니, 제품의 원가를 부담한다고 보시면 됩니다.<br />
                                    - 리뷰의 경우 가이드라인에 제공해주시면 맞추어 작성될 수 있습니다.<br />
                                    다만 가이드라인이 제공되면 리뷰에 ‘제품을 제공 받아 작성한 리뷰입니다.’라는 공정문구를 포함해야 합니다.<br />
                                    - 구매, 텍스트 리뷰, 포토 리뷰, 동영상 리뷰, 찜, Q&A, 네이버블로그 포스팅, 인스타그램 포스팅 총 8가지를 선택해 진행할 수 있습니다.<br />
                                    - 모든 진행은 해당 제품에 관심 있는 실사용자가 진행합니다.<br />
                                    - 결제는 무통장입금으로 진행되고 결제 후 캠페인이 시작되기 전 미리 연락드립니다.<br />
                                    - 캠페인의 결과는 [상위노출 관리]-[이력 및 결과]에서 확인할 수 있습니다.<br />
                                    - 기타 문의는 사이트 우측 하단 ‘채팅문의’를 통해 문의해주세요.

                                </div>
                            </div>
                            <div style="float:right;padding-right:10px;padding-top:8px;r">
                                <i class="far fa-angle-up" style="color:#0264fb"></i>
                            </div>
                        </div>
                        <div class="s-target-b9" v-if="!expandInfo2" @click="expandInfo2 = true" style="cursor:pointer;margin-top:20px">
                            <div style="float:left;padding-left:10px;padding-top:8px">
                                <i class="fal fa-check-circle" style="color:#f2da00"></i> &nbsp;<span class="s-target-font2">상위노출에 필요한 구매수 확인하기</span>
                            </div>
                            <div style="float:right;padding-right:10px;padding-top:8px;">
                                <i class="far fa-angle-down" style="color:#0264fb"></i>
                            </div>
                        </div>
                        <div class="s-target-b9" v-if="expandInfo2" @click="expandInfo2 = false" style="cursor:pointer;margin-top:20px">
                            <div style="float:left;padding-left:10px;padding-top:8px">
                                <i class="fal fa-info-circle" style="color:#f2da00"></i>&nbsp;<span class="s-target-font2">상위노출에 필요한 구매수 확인하기</span>
                                <div class="s-target-font23" style="padding-top:19px;padding-right:5px">
                                    상위노출에 필요한 구매수를 확인하고 싶다면 <br />
                                    스토어링크의 상위노출 분석 기능을 통해 확인할 수 있습니다.<br />
                                    <span @click="$router.push('/pages/scan').catch(() => {})">상위노출 분석 바로가기</span><br />
                                    상위노출 분석 페이지에서 키워드를 입력하고,<br />
                                    각 쇼핑몰별로 상위노출에 필요한 구매수, 리뷰수, 찜수 등을 <br />
                                    파악할 수 있습니다.
                                    <img style="width:100%;padding-top:10px;padding-bottom:10px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-analytics1.png?alt=media&token=0faa1ecf-8d52-4540-826d-2f6e46357091" /><br />
                                    추가로 상위노출에 필요한 마케팅 비용과 상위노출 되었을 경우 월 평균 매출을 확인할 수 있어 미리 ROAS(광고수익률)를 확인하고 상위노출 캠페인을 신청할 수 있습니다.
                                    <img style="width:100%;padding-top:10px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-analytics2.png?alt=media&token=9d69b94a-8d28-43c1-967e-55ddc31b79c6" /><br />

                                </div>
                            </div>
                            <div style="float:right;padding-right:10px;padding-top:8px;r">
                                <i class="far fa-angle-up" style="color:#0264fb"></i>
                            </div>
                        </div>
                        <div class="s-target-font4" style="margin-top:10px;">
                            스토어타입 선택
                        </div>
                        <div>
                            <a-select :getPopupContainer="triggerNode => triggerNode.parentNode" default-value="네이버스토어" v-model="service.storetype" style="width:100%" size="large" @change="initValue()">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div style="padding: 4px 8px; cursor: pointer;" @mousedown="e => e.preventDefault()" @click="visible1=true">
                                        <a-icon type="plus" /> 사용자 입력
                                    </div>
                                </div>
                                <a-select-option :value="item.value" v-for="item in storelist" :key="item">
                                    <span role="img" v-if="item.src">
                                        <img class="smalllogo" :src="item.src" />&nbsp;
                                    </span>{{item.label}}

                                </a-select-option>
                            </a-select>
                        </div>
                        <div style="margin-top:10px;" class="input">
                            <div style="display:flex" v-if="service.storetype==ShoppingMall.BAEMIN">
                                <div style="width:100%;">
                                    <div class="s-target-font4">구매가격&nbsp;<span class="s-target-font11">필수 입력란</span></div>
                                    <a-input type="number" v-model="value1" :disabled="eventCodeConfirm||couponCodeConfirm" suffix="원" />

                                </div>
                            </div>
                            <div style="display:flex" v-if="service.storetype==ShoppingMall.NAVER_PLACE">

                            </div>
                            <div v-else>
                                <div style="color:red;font-weight:bold;font-size:18px">
                                    * 캠페인 진행기간 동안 입력하신 스토어 내 상품가격 변경은 불가합니다.
                                </div>
                                <div style="display:flex">
                                    <div style="width:50%;margin-right:5px">
                                        <div class="s-target-font4">상품가격&nbsp;<span class="s-target-font11">필수 입력란</span></div>
                                        <a-input type="number" v-model="value1" :disabled="eventCodeConfirm||couponCodeConfirm" suffix="원" />

                                    </div>
                                    <div style="width:50%;margin-left:5px">
                                        <div class="s-target-font4">배송비&nbsp;<span class="s-target-font11"></span></div>
                                        <a-input type="number" v-model="value2" :disabled="eventCodeConfirm||couponCodeConfirm" suffix="원" />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="width:100%;" class="input" v-if="service.storetype==ShoppingMall.NAVER_PLACE">
                            <div class="s-target-font4">네이버 플레이스 URL&nbsp;<span class="s-target-font11">필수 입력란</span></div>
                            <a-input v-model="service.url" placeholder="URL을 입력 해 주세요" />
                        </div>
                        <div style="width:100%;" class="input" v-else>
                            <div class="s-target-font4">구매 URL&nbsp;<span class="s-target-font11">필수 입력란</span></div>
                            <a-input v-model="service.url" placeholder="구매 URL을 입력 해 주세요" />
                        </div>
                        <div style="width:100%" class="s-target-b3 blink_me" @click="onValidateURL()" v-if="!checkedURL&&(service.storetype==ShoppingMall.NAVER_STORE|| service.storetype==ShoppingMall.NAVER_CROWD|| service.storetype==ShoppingMall.NAVER_WINDOW|| service.storetype==ShoppingMall.NAVER_LIVE)">
                            스마트 URL 매칭
                        </div>
                        <div style="width:100%; background-color: #f2da00;" class="s-target-b3" v-if="checkedURL&&(service.storetype==ShoppingMall.NAVER_STORE|| service.storetype==ShoppingMall.NAVER_CROWD|| service.storetype==ShoppingMall.NAVER_WINDOW|| service.storetype==ShoppingMall.NAVER_LIVE)">
                            매칭 완료 !
                        </div>
                        <div style="width:100%;" class="input" v-if="service.storetype==ShoppingMall.NAVER_STORE|| service.storetype==ShoppingMall.NAVER_CROWD|| service.storetype==ShoppingMall.NAVER_WINDOW|| service.storetype==ShoppingMall.NAVER_LIVE">
                            <div class="s-target-font4">상품 No.</div>
                            <a-input placeholder="자동입력" v-model="service.productno" disabled />
                        </div>
                        <div style="width:100%;" class="input" v-if="service.storetype==ShoppingMall.NAVER_STORE|| service.storetype==ShoppingMall.NAVER_CROWD|| service.storetype==ShoppingMall.NAVER_WINDOW|| service.storetype==ShoppingMall.NAVER_LIVE">
                            <div class="s-target-font4">상점 No.</div>
                            <a-input v-model="service.storeno" :disabled="service.storetype!=ShoppingMall.TMON" />
                        </div>
                        <div style="width:100%;" class="input">
                            <div class="s-target-font4">상점명 &nbsp;<span class="s-target-font11">필수 입력란</span></div>
                            <a-input v-model="service.storename" :disabled="service.storetype==ShoppingMall.NAVER_STORE|| service.storetype==ShoppingMall.NAVER_CROWD|| service.storetype==ShoppingMall.NAVER_WINDOW|| service.storetype==ShoppingMall.NAVER_LIVE" />
                        </div>
                        <div style="width:100%;" class="input" v-if="service.storetype==ShoppingMall.NAVER_STORE|| service.storetype==ShoppingMall.NAVER_CROWD|| service.storetype==ShoppingMall.NAVER_WINDOW|| service.storetype==ShoppingMall.NAVER_LIVE">
                            <div class="s-target-font4">사업자번호</div>
                            <a-input placeholder="자동입력" v-model="service.companyno" v-mask="'###-##-#####'" disabled />
                        </div>
                        <div style="width:100%;" class="input" v-if="service.storetype==ShoppingMall.NAVER_PLACE">
                            <div class="s-target-font4">주문 해야할 음식명 <span style="color:#f2da00">[선택]</span></div>
                            <a-input v-model="service.desc" placeholder="" />
                        </div>
                        <div style="width:100%;" class="input" v-else>
                            <div class="s-target-font4">구매 해야할 제품명(옵션 포함) <span style="color:#f2da00">[선택]</span></div>
                            <a-input v-model="service.desc" placeholder="" />
                        </div>
                        <div style="width:100%;" class="input">
                            <div class="s-target-font4">주의사항 <span style="color:#f2da00">[선택]</span></div>
                            <a-input v-model="service.warning" placeholder="구매 시 주의사항을 적어주세요" />
                        </div>
                        <div style="width:100%;" class="input">
                            <div class="s-target-font4">검색 키워드 작성 -실시간 검색에 노출되는 키워드 <span style="color:#f2da00">[선택]</span></div>
                            <a-input v-model="service.keyword" placeholder="제품 키워드를 적어주세요" />
                        </div>
                        <div style="width:100%" class="s-target-b4" @click="applyDetailDraft()">
                            임시저장
                        </div>
                        <div style="width:100%" class="s-target-b3" @click="onNextStep(0)">
                            다음단계
                        </div>

                    </div>
                    <div class="step2" style="display: table;margin: 0 auto;max-width:394px;width:100%" v-if="step==2">

                        <div class="s-target-font3" style="margin-top:34px;margin-bottom:16px">상위노출 진행 정보 입력</div>
                        <div class="s-target-font5" style="margin-top:10px;">
                            상위노출 서비스
                        </div>

                        <div style="width:100%;" class="right input">
                            <div><span class="s-target-font4" style="padding-top:5px">{{getStore().missionTitle.c1}}</span><span class="s-target-font6" style="float:right;padding-top:0px">최소 {{this.getStore().limit}}명부터 진행이 가능합니다.</span></div>
                            <a-input-number :disabled="eventCodeConfirm||couponCodeConfirm" @change="onChangeBuyValue()" v-model="buy" placeholder="숫자입력" style="width:100%;" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="width:100%;margin-top:1px" class="right input">
                            <div><span class="s-target-font4">{{getStore().missionTitle.c2}}</span></div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm||service.storetype==ShoppingMall.NAVER_PLACE" v-model="text" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="width:100%;" class="right input" v-if="!isNotAllowPhoto">
                            <div><span class="s-target-font4">{{getStore().missionTitle.c3}}</span></div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm" v-model="photo" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="width:100%;" class="right input" v-if="!isNotAllowVIDEO">
                            <div><span class="s-target-font4">동영상리뷰</span></div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm||isNotAllowVIDEO" v-model="video" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="width:100%;" class="right input">
                            <div><span class="s-target-font4">{{getStore().missionTitle.c4}}</span></div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm||service.storetype==ShoppingMall.NAVER_PLACE" v-model="zzim" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="width:100%;" class="right input" v-if="!isNotAllowQA">
                            <div><span class="s-target-font4">{{getStore().missionTitle.c5}}</span></div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm||isNotAllowQA" v-model="qa" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="width:100%;" class="right input" v-if="!isNotAllowReg">
                            <div><span class="s-target-font4">회원가입</span></div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm" v-model="reg" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="margin-top:6px;">
                            <span class="s-target-font7">{{getStore().missionTitle.c1}} {{buy==null ? 0 : buy}}건 &nbsp;<span class="s-target-font8">{{parseInt(buypay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalBuyService.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;">
                            <span class="s-target-font7">{{getStore().missionTitle.c2}} {{text==null ? 0 : text}}건 &nbsp;<span class="s-target-font8">{{parseInt(textpay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalText.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;" v-if="!isNotAllowPhoto">
                            <span class="s-target-font7">{{getStore().missionTitle.c3}} {{photo==null ? 0 : photo}}건 &nbsp;<span class="s-target-font8">{{parseInt(photopay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalPhoto.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;" v-if="!isNotAllowVIDEO">
                            <span class="s-target-font7">동영상 리뷰 {{video==null ? 0 : video}}건 &nbsp;<span class="s-target-font8">{{parseInt(videopay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalVideo.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;">
                            <span class="s-target-font7">{{getStore().missionTitle.c4}} {{zzim==null ? 0 : zzim}}건 &nbsp;<span class="s-target-font8">{{parseInt(zzimpay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalZzim.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;" v-if="!isNotAllowQA">
                            <span class="s-target-font7">{{getStore().missionTitle.c5}} {{qa==null ? 0 : qa}}건 &nbsp;<span class="s-target-font8">{{parseInt(qapay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalQa.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;" v-if="!isNotAllowReg">
                            <span class="s-target-font7">회원가입 {{reg==null ? 0 : reg}}건 &nbsp;<span class="s-target-font8">{{parseInt(regpay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalReg.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;border-bottom:1px solid black;padding-bottom:8px"></div>
                        <div style="margin-top:13px;">
                            <span class="s-target-font7">총 상위노출 서비스 비용</span>
                            <span class="s-target-font7" style="float:right;color:#e02020"> {{(this.getTotalBuyService+this.getTotalMission1).toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:13px;" v-if="service.storetype==ShoppingMall.BAEMIN">
                            <span class="s-target-font7">구매가격</span>
                            <span class="s-target-font7" style="float:right;color:#e02020"> {{this.getTotalProductBuy.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:13px;" v-if="service.storetype==ShoppingMall.NAVER_PLACE">

                        </div>
                        <div style="margin-top:13px;" v-else>
                            <span class="s-target-font7">제품가격 + 배송비</span>
                            <span class="s-target-font7" style="float:right;color:#e02020"> {{this.getTotalProductBuy.toLocaleString()}}원</span>
                        </div>

                        <div class="s-target-font5" style="margin-top:34px;">
                            SNS 홍보
                        </div>
                        <div style="width:100%;" class="right input">
                            <div><span class="s-target-font4">네이버 블로그</span>

                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>- 본 포스팅 서비스는 쇼핑몰에서의 상위노출을 기본으로 진행하되 마이크로급의 블로거(일방문자 100명 이상)를 활용해 추가로 블로그 포스팅까지 낮은 가격에 진행할 수 있는 서비스입니다. <br />높은 일방문자수를 갖거나 메가급의 블로거(일방문자 1만명 이상)를 활용하는 포스팅이 아닙니다.<br />
                                            - 포스팅 작성 및 업로드 완료는 캠페인 진행일로부터 약 3주가 소요되며, 완료일이 지났지만 등록되지 않거나 노쇼가 발생할 경우 미달된 수만큼 새로운 캠페인이 시작되어 블로거를 추가 모집하여 진행됩니다.<br />
                                            - 블로거의 선정은 스토어링크의 최적화 알고리즘에 따라 신청한 블로거중 가장 적합한 블로거가 자동 선정되며, 광고주가 직접 선정할 수 없습니다.<br />
                                            - 포스팅 자체를 상위노출 할 수 있는 블로거, 높은 일방문자수나 메가급의 블로거 연결은 우측 하단 채팅문의를 통해 별도로 문의해주세요.</span>
                                    </template>
                                    <span> &nbsp;<i class="fal fa-question-circle"></i></span>
                                </a-tooltip>

                            </div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm" v-model="naver" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>
                        <div style="width:100%;" class="right input">
                            <div><span class="s-target-font4">인스타그램</span>

                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>
                                            - 본 포스팅 서비스는 쇼핑몰에서의 상위노출을 기본으로 진행하되 마이크로급의 인스타그래머(팔로워수 500명 이상)를 활용해 추가로 인스타그램 포스팅까지 낮은 가격에 진행할 수 있는 서비스입니다.<br /> 높은 일방문자수를 갖거나 메가급의 인스타그래머(팔로워 10만명 이상)를 활용하는 포스팅이 아닙니다.<br />
                                            - 포스팅 작성 및 업로드 완료는 캠페인 진행일로부터 약 3주가 소요되며, 완료일이 지났지만 등록되지 않거나 노쇼가 발생할 경우 미달된 수만큼 새로운 캠페인이 시작되어 인스타그래머를 추가 모집하여 진행됩니다.<br />
                                            - 인스타그래머의 선정은 스토어링크의 최적화 알고리즘에 따라 신청한 인스타그래머중 가장 적합한 인스타그래머가 자동 선정되며, 광고주가 직접 선정할 수 없습니다.<br />
                                            - 포스팅 자체를 상위노출 할 수 있는 인스타그래머, 높은 팔로워수나 메가급의 인스타그래머 연결은 우측 하단 채팅문의를 통해 별도로 문의해주세요.</span>
                                    </template>
                                    <span> &nbsp;<i class="fal fa-question-circle"></i></span>
                                </a-tooltip>

                            </div>
                            <a-input-number style="width:100%;" :disabled="eventCodeConfirm||couponCodeConfirm" v-model="instagram" placeholder="숫자입력" :formatter="value => `${value}개`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '').replace('개', '')" />
                        </div>

                        <div style="margin-top:6px;">
                            <span class="s-target-font7">네이버 블로그 {{naver==null ? 0 : naver}}건 &nbsp;<span class="s-target-font8">{{parseInt(naverpay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalNaver.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:0px;border-bottom:1px solid black;padding-bottom:8px">
                            <span class="s-target-font7">인스타그램 {{instagram==null ? 0 : instagram}}건 &nbsp;<span class="s-target-font8">{{parseInt(instagrampay).toLocaleString()}}원</span></span>
                            <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getTotalInstagram.toLocaleString()}}원</span>
                        </div>
                        <div style="margin-top:13px;">
                            <span class="s-target-font7">총 SNS 홍보 비용</span>
                            <span class="s-target-font7" style="float:right;color:#e02020"> {{this.getTotalMission2.toLocaleString()}}원</span>
                        </div>
                        <div class="s-target-font5" style="margin-top:34px;">
                            결제 예정금액
                        </div>
                        <div style="width:100%;margin-top:10px;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">쿠폰 등록</span></div>
                            <div style="width:100%;display:flex;margin-top:5px">
                                <div style="width:80%">
                                    <a-input v-model="eventcode" :disabled="eventCodeConfirm||couponCodeConfirm" style="width:100%;margin-top:-5px" placeholder="쿠폰 코드를 입력해주세요" />
                                </div>
                                <div style="margin-left:10px;margin-top:-5px">
                                    <a-button type="primary" size="default" :disabled="!eventcode||eventCodeConfirm||couponCodeConfirm" @click="onChangeEventCode()"><b>코드적용</b></a-button>
                                </div>
                            </div>

                        </div>

                        <div style="width:100%;margin-top:10px;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">예치금</span></div>

                            <a-input type="number" v-model="useDeposit" @change="onChangeDeposit" :disabled="deposit==0" suffix="원" />
                        </div>

                        <div style="margin-top:13px;">
                            <span class="s-target-font7">사용 가능 예치금</span>
                            <span class="s-target-font7" style="float:right;color:#e02020"> {{deposit.toLocaleString()}}원</span>
                        </div>

                        <div style="width:100%;margin-top:10px;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">사용가능한 쿠폰</span></div>

                            <a-select :getPopupContainer="triggerNode => triggerNode.parentNode" v-model="selectedCouponName" style="width: 100%;" @change="onHandleCouponChange" :disabled="eventCodeConfirm||couponCodeConfirm">
                                <a-select-option :value="item.key" v-for="item in couponlist" :key="item.key">{{item.name}}</a-select-option>
                            </a-select>
                        </div>
                        <div style="margin-top:13px;">
                            <span class="s-target-font7">총 사용 가능 쿠폰</span>
                            <span class="s-target-font7" style="float:right;color:#e02020"> {{couponlist.length}}개</span>
                        </div>
                        <div style="margin-top:10px;" class="s-target-b5">
                            <div style="padding:30px">
                                <div style="margin-top:13px;">
                                    <span class="s-target-font9">총 상위노출 서비스 비용</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{(this.getTotalBuyService+this.getTotalMission1).toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:13px;">
                                    <span class="s-target-font9">총 SNS 홍보 비용</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{getTotalMission2.toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:13px;border-bottom:1px solid black;padding-bottom:17px">
                                    <span class="s-target-font9" v-if="service.storetype==ShoppingMall.BAEMIN">총 구매가격</span>
                                    <span class="s-target-font9" v-if="service.storetype==ShoppingMall.NAVER_PLACE"></span>
                                    <span class="s-target-font9" v-else>총 제품가격 + 배송비</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;" v-if="service.storetype!=ShoppingMall.NAVER_PLACE"> {{getTotalProductBuy.toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:13px;">
                                    <span class="s-target-font7">총 비용</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{getTotal3.toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:13px;">
                                    <span class="s-target-font7">부가세</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{getVATPay.toLocaleString()}}원</span>
                                </div>

                                <div style="margin-top:13px;border-bottom:1px solid black;padding-bottom:17px">
                                    <span class="s-target-font7">총 서비스 신청 금액&nbsp;<span class="s-target-font8-1">VAT 포함</span></span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{getOriginalPay.toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:13px;">
                                    <span class="s-target-font9">쿠폰 제외 금액(-)</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;color:#0464fb"> {{getCouponPay}}원</span>
                                </div>
                                <div style="margin-top:3px;border-bottom:1px solid black;padding-bottom:17px">
                                    <span class="s-target-font9">예치금 제외 금액(-)</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;color:#0464fb"> {{getDepositePay}}원</span>
                                </div>

                                <div style="margin-top:13px;">
                                    <span class="s-target-font7">최종 결제 금액&nbsp;<span class="s-target-font8">VAT 포함</span></span>
                                    <span class="s-target-font10" style="float:right;padding-top: 0px;"> {{getTotal2.toLocaleString()}}원</span>
                                </div>

                            </div>
                        </div>
                        <div v-if="parseInt(naver) >0">
                            <div class="s-target-font5" style="margin-top:34px;">
                                네이버 블로그
                            </div>

                            <div style="width:100%;" class="input">
                                <div><span class="s-target-font4">필수키워드</span></div>
                                <a-input style="text-align:left" placeholder="필수 키워드를 적어주세요" v-model="posting.naver1" />
                            </div>
                            <div style="width:100%;" class="input">
                                <div><span class="s-target-font4">선택키워드</span></div>
                                <a-input style="text-align:left" placeholder="선택 키워드를 적어주세요" v-model="posting.naver2" />
                            </div>
                            <div style="width:100%;">
                                <div><span class="s-target-font4">미션 가이드</span></div>

                                <a-textarea v-model="posting.naver3" placeholder="블로그 포스팅시 지켜야 할 가이드를 작성해주세요.
다만 배포형태의 포스팅이라 과도한 가이드(30장 이상의 사진, 1천자 이상의 텍스트 등)는 조정될 수 있습니다.
사진컨셉, 강조사항, 주의사항 등을 자유롭게 작성해주세요.
미션가이드를 작성하지 않을 경우 사진 기본5장, 텍스트1,000자, 자유형식으로 포스팅됩니다." :rows="7" />
                            </div>
                            <div style="width:100%;">
                                <div><span class="s-target-font4">공정문구</span>&nbsp;<span class="s-target-font11">필수 입력란</span><br />
                                    <span class="s-target-font12">"판매자에게 제품비의 일부를 지원받았지만 저의 솔직한 후기입니다." 등의 공정문구를 작성해주세요. <br />공정위의 "추천/보증 등에 관한 표시/광고 심사 지침" 개정안에 따라 SNS는 리뷰가이드가 없더라도 공정문구를 필수로 남겨야 합니다.</span></div>

                                <a-textarea v-model="posting.naver4" style="margin-top:10px" :rows="2" />
                            </div>
                        </div>
                        <div v-if="parseInt(instagram) >0">
                            <div class="s-target-font5" style="margin-top:34px;">
                                인스타그램
                            </div>

                            <div style="width:100%;" class="input">
                                <div><span class="s-target-font4">필수 해시태그</span></div>
                                <a-input style="text-align:left" placeholder="필수 해시태그를 적어주세요" v-model="posting.instagram2" />
                            </div>
                            <div style="width:100%;">
                                <div><span class="s-target-font4">미션 가이드</span></div>

                                <a-textarea v-model="posting.instagram3" placeholder="인스타그램 포스팅시 지켜야 할 가이드를 작성해주세요.
다만 배포형태의 포스팅이라 과도한 가이드(고퀄리티 영상 등)는 조정될 수 있습니다.
사진컨셉, 강조사항, 주의사항 등을 자유롭게 작성해주세요.
미션가이드를 작성하지 않을 경우 자유형식으로 포스팅됩니다." :rows="7" />
                            </div>
                            <div style="width:100%;">
                                <div><span class="s-target-font4">공정문구</span>&nbsp;<span class="s-target-font11">필수 입력란</span><br />
                                    <span class="s-target-font12">"판매자에게 제품비의 일부를 지원받았지만 저의 솔직한 후기입니다." 등의 공정문구를 작성해주세요. <br />공정위의 "추천/보증 등에 관한 표시/광고 심사 지침" 개정안에 따라 SNS는 리뷰가이드가 없더라도 공정문구를 필수로 남겨야 합니다.</span></div>

                                <a-textarea v-model="posting.instagram4" style="margin-top:10px" :rows="2" />
                            </div>
                        </div>

                        <div style="width:100%" class="s-target-b4" @click="applyDetailDraft()">
                            임시저장
                        </div>
                        <div style="display:flex">
                            <div style="width:49%;margin-right:1%" class="s-target-b3" @click="onPreviousStep()">
                                이전단계
                            </div>
                            <div style="width:49%;margin-left:1%" class="s-target-b3" @click="onNextStep(1)">
                                다음단계
                            </div>
                        </div>

                    </div>
                    <div class="step3" style="display: table;margin: 0 auto;max-width:394px;width:100%" v-if="step==3">

                        <div class="s-target-font3" style="margin-top:34px;margin-bottom:0px">시작일 입력</div>
                        <div style="width:100%;" class="input">
                            <div><span class="s-target-font4">오픈일 설정</span></div>
                            <a-date-picker :disabled-date="disabledDate" style="width:100%" :valueFormat="date" v-model="picdate" :locale="locale" />
                            <div class="s-target-font12" style="line-height:2;margin-top:7px">
                                ✓ 17시 이후 입금된 캠페인은 당일 진행이 불가하며, 해당 신청 건은 익일이 공휴일 또는 주말인 경우, 차주 영업일에 캠페인 오픈이 가능합니다.<br />
                                예1) 금요일 17:30 입금 시 다음주 월요일에 캠페인 신청 확인 후 요청 오픈일에 진행됩니다.<br />
                                예2) 금요일 16:00 입금 시 익일이 주말이여도 당일오픈 또는 요청 오픈일에 진행 가능합니다
                            </div>

                        </div>

                        <div style="margin-top:0px;display:flex" class="input">
                            <div style="width:50%;margin-right:5px">
                                <div class="s-target-font4">상위노출 진행일수</div>
                                <a-dropdown>
                                    <a-menu slot="overlay" @click="handlePeriodClick" style="max-height:300px;overflow-y:scroll">
                                        <a-menu-item :key="item.key" v-for="item in periods">{{item.title}}</a-menu-item>
                                    </a-menu>
                                    <a-button style="width:100%;text-align:left;padding-left:10px;padding-right:10px;font-size:12px">
                                        {{getPeriod}}
                                        <a-icon style="float:right;padding-top:5px" type="down" />
                                    </a-button>
                                </a-dropdown>

                            </div>
                            <div style="width:50%;margin-left:5px">
                                <div class="s-target-font4" v-if="service.storetype==ShoppingMall.NAVER_PLACE">월별 진행 인원</div>
                                <div class="s-target-font4" v-else>일별 진행 인원</div>
                                <div class="s-target-b6">
                                    {{people}}명
                                </div>

                            </div>
                        </div>

                        <div style="width:100%" class="s-target-b4" @click="applyDetailDraft()">
                            임시저장
                        </div>
                        <div style="display:flex">
                            <div style="width:49%;margin-right:1%" class="s-target-b3" @click="onPreviousStep()">
                                이전단계
                            </div>
                            <div style="width:49%;margin-left:1%" class="s-target-b3" @click="onNextStep(2)">
                                다음단계
                            </div>
                        </div>
                    </div>
                    <div class="step4" style="display: table;margin: 0 auto;max-width:394px;width:100%" v-if="step==4">

                        <div class="s-target-font3" style="margin-top:34px;margin-bottom:0px">무통장 입금을 위한 정보</div>

                        <div style="width:100%;">
                            <div><span class="s-target-font4">사업자 등록증 업로드</span></div>
                        </div>
                        <div class="col-md-10" style="margin-bottom:10px">
                            <a-upload :fileList="fileList" :remove="handleRemove" :beforeUpload="beforeUpload" @change="handleChange">
                                <a-button>
                                    <a-icon type="upload" /> 업로드
                                </a-button>
                            </a-upload>
                        </div>
                        <div style="width:100%;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">대표자명</span></div>
                            <a-input style="text-align:left" placeholder="홍길동" v-model="bank.ceoname" />
                        </div>
                        <div style="width:100%;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">전자세금계산서 발급용 사업자번호</span></div>
                            <a-input style="text-align:left" v-mask="'###-##-#####'" :disabled="uncheck" v-model="service.companyno" placeholder="123-45-678" />
                            <a-checkbox @change="onSelectSCompany" v-model="uncheck">사업자번호 체크 제외</a-checkbox>
                        </div>
                        <div style="width:100%;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">사업자등록증 상 기업명</span></div>
                            <a-input style="text-align:left" placeholder="삼성전자" v-model="bank.companyname" />
                        </div>
                        <div style="width:100%;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">세금계산서 받을 이메일 주소</span></div>
                            <a-input style="text-align:left" v-model="bank.companyemail" placeholder="david@samsung.com" />
                        </div>
                        <div style="width:100%;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">업체 담당자명</span></div>
                            <a-input style="text-align:left" v-model="bank.managername" placeholder="홍길동" />
                        </div>
                        <div style="width:100%;margin-bottom:10px;" class="input">
                            <div><span class="s-target-font4">업체 담당자 연락처</span></div>
                            <a-input style="text-align:left" v-mask="['010#######','010########']" v-model="bank.managerphone" placeholder="01012341234" />
                        </div>
                        <div style="width:100%;margin-bottom:10px" class="input">
                            <div><span class="s-target-font4">업체 담당자 이메일</span></div>
                            <a-input style="text-align:left" v-model="bank.manageremail" placeholder="david@samsung.com" />
                        </div>
                        <div class="s-target-font3" style="margin-top:34px;margin-bottom:16px">리뷰 공정문구 선택</div>

                        <div style="background-color:white;width:100%;height:100%;padding:20px">
                            <div style="text-align:center;padding-top:16px;">
                                <a-switch v-model="chkpolicy" @change="onChangePolicy">
                                    <a-icon type="check" slot="checkedChildren" />
                                    <a-icon type="close" slot="unCheckedChildren" />
                                </a-switch>&nbsp;공정문구를 표시하겠습니다.
                            </div>
                            <div class="s-target-font13" style="text-align:center;padding-top:13px;padding-bottom:23px;border-bottom:1px solid #e5e5e5">
                                “판매자에게 제품비의 일부를 지원받았지만 저의 솔직한 후기입니다.”
                            </div>
                            <div class="s-target-font14" style="padding-top:14px">
                                스토어링크에서 진행하는 상위노출 서비스(구매체험단)에서 리뷰 가이드를 제공하지 않는 경우 공정위 지침상 문제되지 않으나 각 마켓(네이버쇼핑, 쿠팡 등)에서 제품 가격의 50% 이상을 지원해주는 경우 공정문구 표기를 권고하고 있습니다.
                                최근 뒷광고 논란으로 공정위에서 "추천/보증 등에 관한 표시/광고 심사 지침" 개정안을 통해 SNS에서는 리뷰가이드가 없더라도 공정문구를 필수로 남기도록 했습니다.
                                마켓은 SNS에 포함되진 않는다고 볼 수 있으나 마켓 자체적으로 이런 사회적인 상황에 따라 엄격한 가이드를 적용할 수 있기 때문에 공정문구를 남기는 것을 추천드립니다.
                                공정문구를 남기지 않을 경우 각 마켓의 개별적인 지침에 따라 구매평 어뷰징으로 판단되어 불이익을 받을 수 있음을 알려드립니다.
                                다만 원치 않는 판매자분들의 의견도 있어 이런 부분을 판매자분들이 직접 판단해 선택할 수 있도록 구성하였습니다.
                            </div>
                            <div class="s-target-font15" style="padding-top:14px">
                                블로그/인스타그램 포스팅의 경우는 선택여부와 상관없이 공정문구가 필히 포함되어 작성되며,
                                아래 공정문구 표시 선택 시 각 마켓(네이버쇼핑, 쿠팡 등) 리뷰에도 공정문구가 작성됩니다.
                            </div>
                            <div class="s-target-font3" style="margin-top:34px;margin-bottom:10px">스토어링크 담당 MD선택</div>
                            <div class="s-target-font16">
                                본 진행하시는 서비스를 담당 MD를 통해 진행하시는 경우,
                                담당 MD를 꼭 선택해주세요. 없으신 경우 없음을 선택을 해주세요.
                            </div>

                            <div style="display:flex;margin-bottom:13px;padding-top:13px;padding-bottom:13px;border-bottom:1px solid #e8e8e8">
                                <a-select :getPopupContainer="triggerNode => triggerNode.parentNode" showSearch v-model="service.md" placeholder="없으신 경우 없음을 선택해주세요" optionFilterProp="children" style="width:100%" @change="onChangeMD" :filterOption="filterOption">
                                    <a-select-option value="99999">없음</a-select-option>
                                    <a-select-option value="35">김동현 MD님</a-select-option>
                                    <a-select-option value="25">권기인 MD님</a-select-option>
                                    <a-select-option value="16">박영호 MD님</a-select-option>
                                    <a-select-option value="36">박윤정 MD님</a-select-option>
                                    <a-select-option value="38">박현애 MD님</a-select-option>
                                    <a-select-option value="39">안재영 MD님</a-select-option>
                                    <a-select-option value="45">유용성 MD님</a-select-option>
                                    <a-select-option value="46">오윤준 MD님</a-select-option>
                                    <a-select-option value="15">조민제 MD님</a-select-option>
                                    <a-select-option value="13">정용은 MD님</a-select-option>
                                    <a-select-option value="40">황성빈 MD님</a-select-option>
                                    <a-select-option value="43">황의돈 MD님</a-select-option>
                                    <a-select-option value="44">허성진 MD님</a-select-option>
                                </a-select>

                            </div>

                        </div>

                        <div class="s-target-font3" style="margin-top:34px;margin-bottom:16px">캠페인 상품 이미지 등록</div>

                        <div style="background-color:white;padding:20px">

                            <div style="width:100" class="s-target-b3" @click="visibleImg1 = true">상품 썸네일 이미지 (335x335) 만들기</div>
                            <div style="text-align:center;padding-top:16px;">
                                <img :src="thumbnail" v-if="thumbnail" style="width:100%" />
                            </div>
                        </div>

                        <div style="background-color:white;padding:20px;margin-top:10px">
                            <div style="width:100" class="s-target-b3" @click="visibleImg2 = true">상품 상세 이미지 (701x723) 만들기</div>
                            <div style="text-align:center;padding-top:16px;">
                                <img :src="productImg" v-if="productImg" style="width:100%" />
                            </div>
                        </div>
                        <div style="width:100%" class="s-target-b4" @click="applyDetailDraft()">
                            임시저장
                        </div>
                        <div style="display:flex">
                            <div style="width:49%;margin-right:1%" class="s-target-b3" @click="onPreviousStep()">
                                이전단계
                            </div>
                            <div style="width:49%;margin-left:1%" class="s-target-b3" @click="onNextStep(3)">
                                다음단계
                            </div>
                        </div>
                    </div>
                    <div class="step5" style="display: table;margin: 0 auto;max-width:394px;width:100%" v-if="step==5">

                        <div class="s-target-font3" style="margin-top:34px;margin-bottom:0px">결제 금액</div>
                        <div style="margin-top:0px;" class="s-target-b7">
                            <div style="padding:30px">
                                <div style="margin-top:23px;">
                                    <span class="s-target-font9">총 상위노출 서비스 비용</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{(this.getTotalBuyService+this.getTotalMission1).toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:3px;">
                                    <span class="s-target-font9">총 SNS 홍보 비용</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{getTotalMission2.toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:3px;border-bottom:1px solid black;padding-bottom:13px">

                                    <span class="s-target-font9" v-if="service.storetype==ShoppingMall.BAEMIN">총 구매가격</span>
                                    <span class="s-target-font9" v-if="service.storetype==ShoppingMall.NAVER_PLACE"></span>
                                    <span class="s-target-font9" v-else>총 제품가격 + 배송비</span>
                                    <span class="s-target-font7" v-if="service.storetype!=ShoppingMall.NAVER_PLACE" style="float:right;padding-top: 0px;"> {{getTotalProductBuy.toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:13px;">
                                    <span class="s-target-font9">총 서비스 신청 금액&nbsp;<span class="s-target-font8-1">VAT 포함</span></span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{getOriginalPay.toLocaleString()}}원</span>
                                </div>

                                <div style="margin-top:3px;">
                                    <span class="s-target-font9">쿠폰 제외 금액(-)</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;color:#0464fb"> {{getCouponPay}}원</span>
                                </div>
                                <div style="margin-top:3px;border-bottom:1px solid black;padding-bottom:17px">
                                    <span class="s-target-font9">예치금 제외 금액(-)</span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;color:#0464fb"> {{getDepositePay}}원</span>
                                </div>
                                <div style="margin-top:13px;border-bottom:1px dashed black;padding-bottom:13px">
                                    <span class="s-target-font7">최종 결제 금액&nbsp;<span class="s-target-font8">VAT 포함</span>&nbsp;</span><span style="color:orange;font-size:15px">①</span>
                                    <span class="s-target-font10" style="float:right;padding-top: 0px;"> {{getTotal2.toLocaleString()}}원</span>
                                </div>

                                <div style="margin-top:13px;">
                                    <i class="fas fa-asterisk">&nbsp;</i><span class="s-target-font9">부연 설명</span>
                                </div>
                                <div style="margin-top:3px;">
                                    <span class="s-target-font9">제품 예상 정산금액&nbsp;<span class="s-target-font8"></span>&nbsp;<span style="color:blue;font-size:15px">②</span> </span>
                                    <span class="s-target-font7" style="float:right;padding-top: 10px;"> {{getProductPay.toLocaleString()}}원</span>
                                    <a-input type="number" v-model="fees" suffix="%" placeholder="예상 수수료 입력" />
                                </div>
                                <div style="margin-top:13px;">
                                    <span class="s-target-font9">스토어링크 실 이용료&nbsp;<span style="color:green;font-size:15px">③</span></span>
                                    <span class="s-target-font7" style="float:right;padding-top: 0px;"> {{getServicePay.toLocaleString()}}원</span>
                                </div>
                                <div style="margin-top:13px;">
                                    <span class="s-target-font9"><span style="color:orange;font-size:15px">①</span> 최종 결제금액으로 세금계산서 발행해드립니다.</span>
                                </div>
                                <div style="margin-top:3px;">
                                    <span class="s-target-font9"><span style="color:blue;font-size:15px">②</span> 구매체험단이 결제한 쇼핑몰에서 수수료를 제외하고 정산 받는 금액입니다.</span>
                                </div>
                                <div style="margin-top:3px;">
                                    <span class="s-target-font9"><span style="color:green;font-size:15px">③</span> 정산받는 금액을 제외한 스토어링크 실제 이용료 입니다.</span>
                                </div>

                            </div>
                        </div>
                        <div style="margin-top:20px" class="s-target-b8">
                            <div style="padding:30px">
                                <div style="display:flex;margin-bottom:20px">
                                    <div class="s-target-font18" style="position: absolute; margin-bottom: 50px;">
                                        입금정보
                                    </div>
                                    <div style="margin-left:70%;margin-top:5px">
                                        <img style="width:95px;" src="@/assets/images/s-logo-blue.png" />
                                    </div>
                                </div>

                                <a-row>
                                    <a-col :span="5">
                                        <span class="s-target-font19">기업은행</span>
                                    </a-col>
                                    <a-col :span="19">
                                        <span class="s-target-font20">219-108067-04-015</span>
                                    </a-col>

                                    <a-col class="offset" :span="5">
                                        <span class="s-target-font19">예금주</span>
                                    </a-col>
                                    <a-col class="offset" :span="19">
                                        <span class="s-target-font20">주식회사 스토어링크</span>
                                    </a-col>

                                </a-row>

                            </div>

                        </div>

                        <div style="width:100%" class="s-target-b4" @click="applyDetailDraft()">
                            임시저장
                        </div>
                        <div style="display:flex">
                            <div style="width:49%;margin-right:1%" class="s-target-b3" @click="onPreviousStep()">
                                이전단계
                            </div>
                            <div style="width:49%;margin-left:1%" class="s-target-b3" @click="onNextStep(4)">
                                결제하기
                            </div>
                        </div>

                    </div>
                    <div class="step5" style="display: table;margin: 0 auto;max-width:394px;width:100%" v-if="step==7">

                        <div class="s-target-font21" style="margin-top:30px">캠페인 신청 완료</div>
                        <div class="s-target-font22" style="margin-top:10px">
                            캠페인 신청이 완료되었습니다!<br />담당자 확인 후 빠른 시일 내에 연락 드릴게요
                        </div>
                        <div style="text-align:center;margin-top:30px;margin-bottom:30px">
                            <img style="width:284px" src="@/assets/images/complete.png" />
                        </div>
                        <div style="margin-top:20px" class="s-target-b8">
                            <div style="padding:30px">
                                <div style="display:flex">
                                    <div class="s-target-font18" style="min-width:50px">
                                        입금정보
                                    </div>
                                    <div style="margin-left: 180px;margin-top:5px">
                                        <img style="width:95px;" src="@/assets/images/s-logo-blue.png" />
                                    </div>
                                </div>

                                <a-row>
                                    <a-col :span="4">
                                        <span class="s-target-font19">기업은행</span>
                                    </a-col>
                                    <a-col :span="20">
                                        <span class="s-target-font20">219-108067-04-015</span>
                                    </a-col>

                                    <a-col class="offset" :span="4">
                                        <span class="s-target-font19">예금주</span>
                                    </a-col>
                                    <a-col class="offset" :span="20">
                                        <span class="s-target-font20">주식회사 스토어링크</span>
                                    </a-col>

                                </a-row>

                            </div>

                        </div>

                        <div style="width:100%;cursor:pointer" class="s-target-b3" @click="$router.push('/pages/dashboard').catch(() => {})">
                            메인으로 돌아가기
                        </div>

                    </div>

                </div>
            </a-col>
        </a-row>

        <a-modal v-model="visible1" title="추가할 상점 타입을 입력해주세요" width="400px">
            <template slot="footer">
                <a-button key="back" @click="visible1=false">
                    취소
                </a-button>
                <a-button key="submit" type="primary" :loading="loading" @click="onAddStoreType()">
                    등록
                </a-button>
            </template>
            <a-input placeholder="" v-model="_tmp_store" />
        </a-modal>

        <a-modal title="확인" v-model="dialogVisiable7" :closable="false" :maskClosable="false">
            <div>
                <h5>
                    <span style="color:#0264fb;font-weight:bold">스토어 리뷰</span> 작성시 공정문구 표시함에 동의하신 것이 맞으신가요?
                </h5>

            </div>
            <template slot="footer">
                <a-button key="back" @click="dialogVisiable7=false">아니요</a-button>
                <a-button key="submit" type="primary" @click="onConfirmPolicy()">
                    네 맞습니다. 다음으로 넘어가겠습니다.
                </a-button>

            </template>
        </a-modal>

        <a-modal title="확인" v-model="dialogMsg" :closable="false" :maskClosable="false">
            <div v-html="confirmMessage">

            </div>
            <template slot="footer">
                <a-button key="back" @click="dialogMsg=false">아니요</a-button>
                <a-button key="submit" type="primary" @click="onConfirmMessage()">
                    네 확인했습니다. 다음으로 넘어가겠습니다.
                </a-button>

            </template>
        </a-modal>

        <a-modal v-model="visibleImg1" title="상품 썸네일 이미지를 만들어 주세요" width="400px" :closable="false" :maskClosable="false">
            <template slot="footer">
                <a-button key="back" @click="visibleImg1=false">
                    취소
                </a-button>
                <a-button key="submit" type="primary" @click="onAddThumbnail()" :disabled="originalThumbnail==null">
                    등록
                </a-button>
            </template>

            <div style="margin-bottom:10px">
                <a-upload :fileList="fileList2" :remove="handleRemove2" :beforeUpload="beforeUpload2" @change="handleChange2">
                    <a-button style="width:100%">
                        <a-icon type="upload" /> 이미지 업로드 하기
                    </a-button>
                </a-upload>
            </div>
            <div>
                <cropper class="cropper" ref="cropper" :stencil-props="{ aspectRatio: 1/1 }" :src="originalThumbnail"></cropper>
            </div>
        </a-modal>

        <a-modal v-model="visibleImg2" title="상품 상세 이미지를 만들어 주세요" width="700px" :closable="false" :maskClosable="false">
            <template slot="footer">
                <a-button key="back" @click="visibleImg2=false">
                    취소
                </a-button>
                <a-button key="submit" type="primary" @click="onAddProductImg()" :disabled="originalProductImg==null">
                    등록
                </a-button>
            </template>
            <div style="margin-bottom:10px">
                <a-upload :fileList="fileList3" :remove="handleRemove3" :beforeUpload="beforeUpload3" @change="handleChange3">
                    <a-button>
                        <a-icon type="upload" /> 이미지 업로드 하기
                    </a-button>
                </a-upload>
            </div>
            <div>
                <cropper class="cropper2" ref="cropper2" :stencil-props="{ aspectRatio: 1/1 }" :src="originalProductImg"></cropper>
            </div>
        </a-modal>

        <a-modal title="공지사항" v-model="arrive" v-if="notifyMsg" :closable="false" :maskClosable="false">
            <div>
                <div v-html="notifyMsg" />
            </div>
            <template slot="footer">
                <a-button key="back" @click="arrive=false">취소</a-button>
                <a-button key="submit" type="primary" @click="handleOkNotify">
                    확인 했습니다. 7일동안 그만 보기
                </a-button>
            </template>
        </a-modal>

    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
};

String.prototype.trims = function () {
    return this.replace(/^\s+|\s+$/g, "");
};

import {
    Cropper
} from "vue-advanced-cropper";

import {
    getLicense,
    replaceAll,
    getCurrentTime,
    getCurrentTime2,
    getCurrentTimeNew,
    getCurrentHour,
    isNaN,
    formatNumber,
    getSR,
    isMobile,
    showError,
    showSuccess,
    ShoppingMall
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";
import TargetNavbar from "./TargetNavbar";
import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";

export default {
    components: {
        NavFooter,
        TargetNavbar,
        Cropper,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    },
    data() {
        return {
            ShoppingMall: ShoppingMall,
            visibleImg1: false,
            visibleImg2: false,
            offSet: 8,
            span: 8,
            checkedURL: false,
            _tmp_store: "",
            visible1: false,
            fees: 6,
            dummy: null,
            expandInfo: false,
            expandInfo2: false,
            step: 1,
            locale,
            selectedCouponName: "",
            mdlist: [{
                    value: "99999",
                    name: "없음"
                },
                {
                    value: "35",
                    name: "김동현 MD님"
                }, ,
                {
                    value: "16",
                    name: "박영호 MD님"
                },
                {
                    value: "36",
                    name: "박윤정 MD님"
                },
                {
                    value: "38",
                    name: "박현애 MD님"
                },
                {
                    value: "39",
                    name: "안재영 MD님"
                },
                {
                    value: "15",
                    name: "조민제 MD님"
                },
                {
                    value: "13",
                    name: "정용은 MD님"
                },
                {
                    value: "40",
                    name: "황성빈 MD님"
                },
                {
                    value: "43",
                    name: "황의돈 MD님"
                },
                {
                    value: "44",
                    name: "허성진 MD님"
                }
            ],
            storelist: [{
                    value: ShoppingMall.NAVER_STORE,
                    label: ShoppingMall.NAVER_STORE,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver.png?alt=media&token=8f71b90e-e771-46ae-825b-4111706156a7"
                },
                {
                    value: ShoppingMall.NAVER_WINDOW,
                    label: ShoppingMall.NAVER_WINDOW,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver-beauty%20(1).png?alt=media&token=fecfc02e-3890-41de-a763-62421e6b0afa"
                },
                {
                    value: ShoppingMall.NAVER_CROWD,
                    label: ShoppingMall.NAVER_CROWD,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver.png?alt=media&token=8f71b90e-e771-46ae-825b-4111706156a7"
                },
                {
                    value: ShoppingMall.NAVER_PLACE,
                    label: ShoppingMall.NAVER_PLACE,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-place.png?alt=media&token=4c41313a-f501-477a-8f0c-8474ace3b4b2"
                },
                {
                    value: ShoppingMall.NAVER_LIVE,
                    label: ShoppingMall.NAVER_LIVE,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Ffavicon_live_pc_32_32.ico?alt=media&token=ac1d78c0-fbcd-4793-849b-cfb7e4581edc"
                },

                {
                    value: ShoppingMall.COUPANG,
                    label: ShoppingMall.COUPANG,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-coupang.png?alt=media&token=8f9fcc4a-911c-4e8a-8b3b-b9c82ae8cd8c"
                },
                {
                    value: ShoppingMall.KAKAO,
                    label: ShoppingMall.KAKAO,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-kakao.png?alt=media&token=c1404cd1-bf08-4594-9f4a-e70751c3e31d"
                },
                {
                    value: ShoppingMall.TALK,
                    label: ShoppingMall.TALK,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-talk.png?alt=media&token=9343a52b-1c9f-42b6-9ef2-7f4ba294d8f1"
                },
                {
                    value: ShoppingMall.CJ,
                    label: ShoppingMall.CJ,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-cj.png?alt=media&token=c73bd252-1cdd-4b7a-a13e-e22748c19bcc"
                },
                {
                    value: ShoppingMall.ELEVEN,
                    label: ShoppingMall.ELEVEN,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-11.png?alt=media&token=d9a1f10f-805b-4ada-9367-906f47240691"
                },
                {
                    value: ShoppingMall.GMARKET,
                    label: ShoppingMall.GMARKET,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-g.png?alt=media&token=dd336c8d-b45d-490b-adde-cd783224e831"
                },
                {
                    value: ShoppingMall.AUCTION,
                    label: ShoppingMall.AUCTION,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-a.png?alt=media&token=5f055dfa-71f7-49ba-97d5-6cfb5968d9a0"
                },
                {
                    value: ShoppingMall.GSSHOP,
                    label: ShoppingMall.GSSHOP,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-gsshop.png?alt=media&token=fa83709c-3176-4728-9f26-114156134b8a"
                },
                {
                    value: ShoppingMall.TMON,
                    label: ShoppingMall.TMON,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-tmon.png?alt=media&token=e94b55ad-6ad9-46aa-9ea6-4907667ad368"
                },
                {
                    value: ShoppingMall.WEMAFE,
                    label: ShoppingMall.WEMAFE,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-wemafe.png?alt=media&token=91b750f8-8fd1-4089-b9d9-d0408350495c"
                },
                {
                    value: ShoppingMall.WADIZ,
                    label: ShoppingMall.WADIZ,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-wadiz.png?alt=media&token=bd4ac3b0-816c-41cf-9ecb-df871be02de2"
                },
                {
                    value: ShoppingMall.BAEMIN,
                    label: ShoppingMall.BAEMIN,
                    src: "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-bamin.png?alt=media&token=4b64aaaa-2ea6-4245-ac84-4413b97805b7"
                }
            ],
            service: {
                storetype: ShoppingMall.NAVER_STORE,
                storename: "",
                url: "",
                productno: "",
                sn: "",
                storeno: "",
                companyno: "",
                exstoretype: "",
                imgblob: "",
                desc: "",
                keyword: "",
                warning: "",
                md: undefined
            },
            imagetype: "IMAGE",
            msg: "",
            arrive: null,
            notifyMsg: "",
            uncheck: false,
            eventCodeConfirm: false,
            couponCodeConfirm: false,
            dialogVisiable7: false,
            isCheckPolicy: false,
            picdate: "",
            couponlist: [],
            pcouponlist: [],
            ucouponlist: [],
            progressDot: 0,
            originalD: 0,
            original: "",
            preview: ["img/timon-inform.jpg"],
            chkpolicy: false,
            stack: 0,
            useDeposit: 0,
            deposit: 0,
            campaigndata1: [],
            campaigndata2: [],
            campaigndata3: [],
            campaigndata4: [],
            campaigndata5: [],
            c1: "구매",
            n1: 0,
            t1: 0,
            c2: "텍스트리뷰",
            n2: 0,
            t2: 0,
            c3: "포토리뷰",
            n3: 0,
            t3: 0,
            c8: "동영상리뷰",
            n8: 0,
            t8: 0,
            c4: "찜하기",
            n4: 0,
            t4: 0,
            c5: "Q&A",
            c9: "회원가입",
            n9: 0,
            t9: 0,
            n5: 0,
            t5: 0,
            e1: [],
            e2: [],
            e3: [],
            e4: [],
            e5: [],
            selectedApplyData: {},
            activeTabName: "first",
            applyTitle: "",
            applyData: [],
            tabData: "a",
            loadingtbl: false,
            dialogVisiable: false,
            dialogVisiable2: false,
            dialogVisiable3: false,
            dialogVisiable4: false,
            dialogVisiable5: true,
            dialogVisiable6: false,
            dialogVisiable6Confirm: false,
            dialogMsg: false,
            confirmMessage: "",
            eventcode: "",
            coupon: "",
            couponMaster: "",
            urlloading: false,
            status: false,
            period: 0,
            people: 0,
            value1: "",
            value2: "0",
            buy: null,
            text: null,
            photo: null,
            video: null,
            zzim: null,
            qa: null,
            naver: null,
            instagram: null,
            reg: null,
            total: 0,
            totalvat: 0,
            thumbnail: null,
            originalThumbnail: null,
            productImg: null,
            originalProductImg: null,
            buypay: "0",
            textpay: "0",
            photopay: "0",
            videopay: "5000",
            zzimpay: "0",
            qapay: "0",
            regpay: "0",
            naverpay: "10000",
            instagrampay: "5000",
            couponQty: 0,
            couponQtyT: 0,
            couponQtyP: 0,
            couponQtyZ: 0,
            couponQtyQ: 0,
            couponQtyV: 0,
            couponQtyR: 0,
            periods: [],
            fileList: [],
            fileList2: [],
            fileList3: [],
            uploading: false,
            posting: {
                naver1: "",
                naver2: "",
                naver3: "",
                naver4: "판매자에게 제품비의 일부를 지원받았지만 저의 솔직한 후기입니다.",
                instagram2: "",
                instagram3: "",
                instagram4: "판매자에게 제품비의 일부를 지원받았지만 저의 솔직한 후기입니다."
            },

            bank: {
                ceoname: "",
                companyname: "",
                companyemail: "",
                managername: "",
                manageremail: "",
                managerphone: "",
                companynoimg: ""
            },
            file: {
                naver: "",
                instagram: ""
            }
        };
    },

    mounted() {
        this.loadDefaultInfo();
        this.initStoreList();
        this.onLoadNotify();
        document.getElementById("fullroot").scrollTop = 0;
        fbq("track", "PageView");
    },
    computed: {
        getServicePay() {
            var _r =
                this.getTotal2 -
                parseInt(this.getTotalProductBuy * (100 - this.fees) / 100);
            return _r < 0 ? 0 : _r;
        },
        getProductPay() {
            var _r = parseInt(this.getTotalProductBuy * (100 - this.fees) / 100);
            return _r < 0 ? 0 : _r;
        },
        isNotAllowQA() {
            if (
                this.service.storetype == ShoppingMall.NAVER_STORE ||
                this.service.storetype == ShoppingMall.NAVER_WINDOW ||
                this.service.storetype == ShoppingMall.NAVER_CROWD ||
                this.service.storetype == ShoppingMall.NAVER_LIVE ||
                this.service.storetype == ShoppingMall.WADIZ ||
                this.service.storetype == ShoppingMall.TALK ||
                this.service.storetype == ShoppingMall.KAKAO
            ) {
                return false;
            } else {
                return true;
            }
        },
        isNotAllowVIDEO() {
            if (
                this.service.storetype == ShoppingMall.NAVER_STORE ||
                this.service.storetype == ShoppingMall.NAVER_WINDOW ||
                this.service.storetype == ShoppingMall.NAVER_CROWD ||
                this.service.storetype == ShoppingMall.NAVER_LIVE ||
                this.service.storetype == ShoppingMall.ELEVEN
            ) {
                return false;
            } else {
                return true;
            }
        },
        isNotAllowPhoto() {
            if (
                this.service.storetype == ShoppingMall.NAVER_PLACE ||
                this.service.storetype == ShoppingMall.WADIZ
            ) {
                return true;
            } else {
                return false;
            }
        },
        isNotAllowReg() {
            if (this.service.storetype.substr(0, 3) == "자사몰") {
                return false;
            } else {
                return true;
            }
        },
        getCreatedTime() {
            return getCurrentTime(
                new Date(this.$store.state.AppActiveUser.createdAt)
            );
        },
        getMargin() {
            return this.$store.state.AppActiveUser.remain > 0 ?
                this.$store.state.AppActiveUser.remain :
                0;
        },
        getExpireDate() {
            return getCurrentTime(
                new Date().addDays(this.$store.state.AppActiveUser.remain)
            );
        },
        getAlt1() {
            return this.$store.state.AppActiveUser.alt1;
        },
        getAlt2() {
            return this.$store.state.AppActiveUser.alt2;
        },
        getName() {
            return this.$store.state.AppActiveUser.displayName;
        },
        getPhone() {
            return this.$store.state.AppActiveUser.phone;
        },
        getUid() {
            return this.$store.state.AppActiveUser.uid;
        },
        getEmail() {
            return this.$store.state.AppActiveUser.email;
        },
        getIsAgency() {
            return this.$store.state.AppActiveUser.isagency;
        },
        getAgency() {
            return this.$store.state.AppActiveUser.agency;
        },
        getUserKey() {
            return this.$store.state.AppActiveUser.key;
        },
        getMerchatUid() {
            return this.$store.state.AppActiveUser.uid + "_" + new Date().getTime();
        },
        getDepositePay() {
            return parseInt(this.useDeposit).toLocaleString();
        },
        getTotalBuy() {
            return (
                (parseInt(this.value1) +
                    parseInt(this.value2) +
                    parseInt(this.buypay)) *
                parseInt(this.buy)
            );
        },
        getTotalProductBuy() {
            return (
                (parseInt(this.value1) + parseInt(this.value2)) * parseInt(this.buy)
            );
        },
        getTotalBuyService() {
            return parseInt(this.buypay) * parseInt(this.buy);
        },

        getTotalAbroadBuy() {
            var _u = this.getTotal1 + this.getTotal1 * 0.1;
            var _u2 = _u + _u * 0.1;
            return "$" + (_u2 / this.target.currency).toFixed(2).toLocaleString();
        },
        getTotalText() {
            return parseInt(this.getText) * parseInt(this.textpay);
        },
        getTotalPhoto() {
            return parseInt(this.getPhoto) * parseInt(this.photopay);
        },
        getTotalVideo() {
            return parseInt(this.getVideo) * parseInt(this.videopay);
        },
        getTotalZzim() {
            return parseInt(this.getZzim) * parseInt(this.zzimpay);
        },
        getTotalQa() {
            return parseInt(this.getQa) * parseInt(this.qapay);
        },
        getTotalNaver() {
            return parseInt(this.getNaver) * parseInt(this.naverpay);
        },
        getTotalInstagram() {
            return parseInt(this.getInstagram) * parseInt(this.instagrampay);
        },
        getTotalReg() {
            return parseInt(this.getReg) * parseInt(this.regpay);
        },
        getCouponBuyPay() {
            if (
                this.service.storetype == ShoppingMall.NAVER_PLACE &&
                this.getIsAgency == "T"
            ) {
                return parseInt(this.couponQty * 5000).toLocaleString();
            } else if (this.service.storetype == ShoppingMall.NAVER_PLACE) {
                return parseInt(this.couponQty * 10000).toLocaleString();
            } else if (
                this.service.storetype == ShoppingMall.WADIZ &&
                this.getIsAgency == "T"
            ) {
                return parseInt(this.couponQty * 4000).toLocaleString();
            } else if (this.service.storetype == ShoppingMall.WADIZ) {
                return parseInt(this.couponQty * 7000).toLocaleString();
            } else if (this.getIsAgency == "T") {
                return parseInt(this.couponQty * 4000).toLocaleString();
            } else if (this.getIsAgency == "3") {
                return parseInt(this.couponQty * 3000).toLocaleString();
            } else if (this.getIsAgency == "2.5") {
                return parseInt(this.couponQty * 2500).toLocaleString();
            } else {
                return parseInt(this.couponQty * 5000).toLocaleString();
            }
        },
        getCouponPay() {
            return (
                this.couponQty * this.buypay +
                this.couponQtyT * this.textpay +
                this.couponQtyP * this.photopay +
                this.couponQtyV * this.videopay +
                this.couponQtyZ * this.zzimpay +
                this.couponQtyQ * this.qapay +
                this.couponQtyR * this.regpay
            ).toLocaleString();
        },
        getTotalMission1() {
            return (
                this.getTotalText +
                this.getTotalPhoto +
                this.getTotalVideo +
                this.getTotalZzim +
                this.getTotalQa +
                this.getTotalReg
            );
        },
        getTotalMission2() {
            return this.getTotalNaver + this.getTotalInstagram;
        },
        getTotal1() {
            return (
                this.getTotalBuy +
                this.getTotalText +
                this.getTotalPhoto +
                this.getTotalVideo +
                this.getTotalZzim +
                this.getTotalQa +
                this.getTotalReg +
                this.getTotalNaver +
                this.getTotalInstagram -
                (this.couponQty * this.buypay +
                    this.couponQtyT * this.textpay +
                    this.couponQtyP * this.photopay +
                    this.couponQtyV * this.videopay +
                    this.couponQtyZ * this.zzimpay +
                    this.couponQtyQ * this.qapay +
                    this.couponQtyR * this.regpay)
            );
        },
        getTotal3() {
            return (
                this.getTotalBuy +
                this.getTotalText +
                this.getTotalPhoto +
                this.getTotalVideo +
                this.getTotalZzim +
                this.getTotalQa +
                this.getTotalReg +
                this.getTotalNaver +
                this.getTotalInstagram
            );
        },
        getOriginalPay() {
            return parseInt(this.getTotal3 + this.getTotal3 * 0.1).toLocaleString();
        },
        getVATPay() {
            return parseInt(this.getTotal3 * 0.1).toLocaleString();
        },
        getPeriod() {
            return this.period == 0 ?
                "진행일을 선택해주세요" :
                this.period.toString() + "일";
        },
        getTotal2() {
            return parseInt(
                (this.getTotal1 + this.getTotal1 * 0.1 - this.useDeposit).toString()
            );
        },
        getText() {
            return this.text == null ? 0 : this.text;
        },
        getPhoto() {
            return this.photo == null ? 0 : this.photo;
        },
        getVideo() {
            return this.video == null ? 0 : this.video;
        },
        getZzim() {
            return this.zzim == null ? 0 : this.zzim;
        },
        getQa() {
            return this.qa == null ? 0 : this.qa;
        },
        getNaver() {
            return this.naver == null ? 0 : this.naver;
        },
        getInstagram() {
            return this.instagram == null ? 0 : this.instagram;
        },
        getReg() {
            return this.reg == null ? 0 : this.reg;
        },
        _mobile: function () {
            var _mobile = this.$vssWidth < 1024 ? true : isMobile();
            this.span = _mobile ? 24 : 8;
            this.offSet = _mobile ? 0 : 8;
            return _mobile;
        }
    },
    methods: {
        onLoadNotify() {
            var val = this.$cookies.get("notifyflag_c1");
            if (val) {} else {
                this.arrive = true;
                var db = firebase.firestore();
                var self = this;
                db
                    .collection("notify")
                    .where("cid", "==", "1")
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            self.notifyMsg = doc.data().contents;
                        });
                    });
            }
        },
        handleOkNotify() {
            this.$cookies.config("7d");
            this.$cookies.set("notifyflag_c1", "T");
            this.arrive = false;
        },
        getStore() {
            return this.storelist.find(item => item.value == this.service.storetype);
        },
        onAddThumbnail() {
            const result = this.$refs.cropper.getResult();
            var _img = result.canvas.toDataURL("image/jpeg");
            this.visibleImg1 = false;
            if (_img) {
                this.saveBase64ToFirebaseStorage1(_img.substr(23));
            }
        },
        saveBase64ToFirebaseStorage1(items) {
            this.$vs.loading();

            var _key = new Date().getTime();
            var self = this;

            var storageRef = firebase.storage().ref();

            // var _name = items.name.replace(
            //   /[~`!#$%\^&*+=\-\[\]\\';,/{}()|\\":<>\?]/g,
            //   ""
            // );

            var uploadTask = storageRef
                .child("storage/" + this.getUid + "/" + _key)
                .putString(items, "base64");

            uploadTask.on(
                "state_changed",
                function (snapshot) {
                    var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                    self.progressValue = Math.round(progress);
                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log("Upload is paused");
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log("Upload is running");
                            break;
                    }
                },
                function (error) {
                    console.log(error);
                },
                function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        self.thumbnail = downloadURL;
                        // console.log(self.thumbnail);
                    });
                    self.$vs.loading.close();
                }
            );
        },
        onAddProductImg() {
            const result = this.$refs.cropper2.getResult();
            var _img = result.canvas.toDataURL("image/jpeg");
            this.visibleImg2 = false;
            this.saveBase64ToFirebaseStorage2(_img.substr(23));
        },
        saveBase64ToFirebaseStorage2(items) {
            this.$vs.loading();

            var _key = new Date().getTime();
            var self = this;

            var storageRef = firebase.storage().ref();

            // var _name = items.name.replace(
            //   /[~`!#$%\^&*+=\-\[\]\\';,/{}()|\\":<>\?]/g,
            //   ""
            // );

            var uploadTask = storageRef
                .child("storage/" + this.getUid + "/" + _key)
                .putString(items, "base64");

            uploadTask.on(
                "state_changed",
                function (snapshot) {
                    var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                    self.progressValue = Math.round(progress);
                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log("Upload is paused");
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log("Upload is running");
                            break;
                    }
                },
                function (error) {
                    console.log(error);
                },
                function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        self.productImg = downloadURL;
                        // console.log(self.productImg);
                    });
                    self.$vs.loading.close();
                }
            );
        },
        onChangeBuyValue() {
            this.period = 0;
            this.people = 0;

            if (this.service.storetype == ShoppingMall.NAVER_PLACE) {
                this.text = this.buy;
                this.zzim = this.buy;
                this.naver = this.buy;
            } else if (this.service.storetype.substr(0, 3) == "자사몰") {
                this.reg = this.buy;
            }
        },
        initStoreList() {
            for (var i = 0; i < this.storelist.length; i++) {
                this.storelist[i]["missionTitle"] = {
                    c1: "구매체험단",
                    c2: "텍스트미션",
                    c3: "포토미션",
                    c4: "찜하기",
                    c5: "Q&A"
                };
                this.storelist[i]["errorType1"] = "구매 수량은 필수 입력 값 입니다.";
                this.storelist[i]["errorType2"] =
                    "구매 갯수가 포토 리뷰 + 동영상리뷰 + 텍스트리뷰 수보다 작으면 안됩니다.";
                this.storelist[i]["errorType3"] =
                    "구매 갯수가 찜수보다 작으면 안됩니다.";
                this.storelist[i]["errorType4"] =
                    "구매 갯수가 Q&A수보다 작으면 안됩니다.";
                this.storelist[i]["errorType5"] = "최소 구매 갯수는 10개 입니다.";
                this.storelist[i]["limit"] = 10;
            }

            //[3] Naver Place
            this.storelist[3]["missionTitle"] = {
                c1: "네이버예약",
                c2: "영수증리뷰",
                c3: "",
                c4: "플레이스저장",
                c5: ""
            };
            this.storelist[3]["limit"] = 5;

            this.storelist[3]["errorType1"] = "예약 수량은 필수 입력 값 입니다.";
            this.storelist[3]["errorType2"] =
                "예약 수량이 영수증 리뷰수보다 작으면 안됩니다.";
            this.storelist[3]["errorType3"] =
                "예약 수량이 플레이스 저장수보다 작으면 안됩니다.";
            this.storelist[3]["errorType4"] =
                "예약 수량은 Q&A수보다 작으면 안됩니다.";
            this.storelist[3]["errorType5"] = "최소 예약 수량은 5개 입니다.";

            //[15]Wadiz
            this.storelist[15]["missionTitle"] = {
                c1: "펀딩하기",
                c2: "응원리뷰",
                c3: "",
                c4: "찜하기",
                c5: "지지서명하기"
            };

            this.storelist[15]["errorType1"] = "펀딩 수량은 필수 입력 값 입니다.";
            this.storelist[15]["errorType2"] =
                "펀딩수량이 응원 리뷰수보다 작으면 안됩니다.";
            this.storelist[15]["errorType3"] =
                "펀딩수량이 찜하기 수보다 작으면 안됩니다.";
            this.storelist[15]["errorType4"] =
                "펀딩수량이 지지서명하기 수보다 작으면 안됩니다.";
            this.storelist[15]["errorType5"] = "최소 펀딩 수량은 10개 입니다.";
        },
        initValue() {
            this.text = null;
            this.photo = null;
            this.video = null;
            this.zzim = null;
            this.qa = null;
            this.naver = null;
            this.instagram = null;
            this.reg = null;

            if (
                this.service.storetype == ShoppingMall.NAVER_PLACE &&
                this.getIsAgency == "T"
            ) {
                this.buypay = "5000";
            } else if (
                this.service.storetype == ShoppingMall.NAVER_PLACE &&
                this.getIsAgency == "F"
            ) {
                this.buypay = "10000";
            } else {
                if (this.getIsAgency == "T") {
                    this.buypay = "4000";
                } else if (this.getIsAgency == "3") {
                    this.buypay = "3000";
                } else if (this.getIsAgency == "2.5") {
                    this.buypay = "2500";
                } else if (this.service.storetype == ShoppingMall.NAVER_PLACE) {
                    this.buypay = "10000";
                } else if (this.service.storetype == ShoppingMall.WADIZ) {
                    this.buypay = "7000";
                } else {
                    this.buypay = "5000";
                }
            }
            if (
                this.service.storetype == ShoppingMall.NAVER_PLACE &&
                this.getIsAgency == "F"
            ) {
                this.buypay = "10000";
            } else if (
                this.getIsAgency == "T" ||
                this.getIsAgency == "3" ||
                this.getIsAgency == "2.5"
            ) {
                this.textpay = "0";
            } else {
                this.textpay = "1000";
            }

            if (
                this.getIsAgency == "T" ||
                this.getIsAgency == "3" ||
                this.getIsAgency == "2.5"
            ) {
                this.photopay = "0";
            } else {
                this.photopay = "2000";
            }

            this.videopay = "5000";

            if (this.service.storetype == ShoppingMall.NAVER_PLACE) {
                this.zzimpay = "0";
                this.service.storeno = "네이버플레이스";
                this.textpay = "0";
                this.value1 = 0;
            } else if (
                this.getIsAgency == "T" ||
                this.getIsAgency == "3" ||
                this.getIsAgency == "2.5"
            ) {
                this.zzimpay = "0";
            } else {
                this.zzimpay = "1000";
            }

            if (this.service.storetype == ShoppingMall.WADIZ) {
                this.qapay = "2000";
            } else if (
                this.getIsAgency == "T" ||
                this.getIsAgency == "3" ||
                this.getIsAgency == "2.5"
            ) {
                this.qapay = "0";
            } else if (this.service.storetype == ShoppingMall.WADIZ) {
                this.qapay = "2000";
            } else {
                this.qapay = "1000";
            }

            if (this.service.storetype == ShoppingMall.NAVER_PLACE) {
                this.text = this.buy;
                this.zzim = this.buy;
            }

            if (this.service.storetype.substr(0, 3) == "자사몰") {
                this.reg = this.buy;
                this.regpay = "1000";
            }
        },
        onChangeEventCode() {
            //check event code
            this.eventcode = this.eventcode.trims();

            var _c = this.pcouponlist.find(item => item.id == this.eventcode);

            if (_c) {
                showSuccess({
                    notify: this.$vs.notify,
                    msg: "성공적으로 적용 되었습니다."
                });

                this.eventCodeConfirm = true;
                this.couponQty =
                    _c.qty > parseInt(this.buy) ? parseInt(this.buy) : _c.qty;

                if (_c.data.type == "S") {
                    this.couponQtyT =
                        _c.qty > parseInt(this.getText) ? parseInt(this.getText) : _c.qty;

                    this.couponQtyP =
                        _c.qty > parseInt(this.getPhoto) ? parseInt(this.getPhoto) : _c.qty;

                    this.couponQtyZ =
                        _c.qty > parseInt(this.getZzim) ? parseInt(this.getZzim) : _c.qty;

                    this.couponQtyQ =
                        _c.qty > parseInt(this.getQa) ? parseInt(this.getQa) : _c.qty;

                    this.couponQtyR =
                        _c.qty > parseInt(this.getReg) ? parseInt(this.getReg) : _c.qty;
                }
            } else {
                showError({
                    notify: this.$vs.notify,
                    msg: "존제하지 않는 쿠폰 코드 입니다."
                });
            }
        },
        onHandleCouponChange(value) {
            this.couponMaster = "";
            this.coupon = "";

            var _c = this.couponlist.find(item => item.key == value);

            if (_c) {
                showSuccess({
                    notify: this.$vs.notify,
                    msg: "성공적으로 적용 되었습니다."
                });
                this.couponCodeConfirm = true;

                this.couponMaster = _c.cid;
                this.selectedCouponName = _c.name;

                this.couponQty =
                    _c.data.c1 > parseInt(this.buy) ? parseInt(this.buy) : _c.data.c1;
                this.coupon = value;
                //change input to disable
                //change button to disable
            } else {
                showError({
                    notify: this.$vs.notify,
                    msg: "존제하지 않는 코드 입니다."
                });
            }
        },
        onChangeDeposit(e) {
            const {
                value
            } = e.target;

            if (
                value > this.originalD ||
                value < 0 ||
                value > parseInt(this.getTotal3 + this.getTotal3 * 0.1)
            ) {
                showError({
                    notify: this.$vs.notify,
                    msg: "예치금 금액이 잘못되었습니다."
                });

                this.useDeposit = 0;
                this.deposit = this.originalD;
            } else {
                this.deposit = this.originalD - value < 0 ? 0 : this.originalD - value;
            }
        },
        async loadDefaultInfo() {
            var self = this;
            var sref = firebase.database().ref("rank_apply/");

            sref
                .orderByChild("uid")
                .equalTo(this.getUid)
                .limitToLast(1)
                .once("value", function (sdata) {
                    // sref.once("value", function (sdata) {

                    sdata.forEach(function (sdatas) {
                        self.bank.ceoname = sdatas.val().scompanyceo;
                        self.bank.companyno = sdatas.val().scompanyno;
                        self.service.companyno = sdatas.val().scompanyno;
                        self.bank.companyname = sdatas.val().scompanyname;
                        self.bank.companynoimg = sdatas.val().scompayimage;
                        self.bank.companyemail = sdatas.val().scomapyemail;
                        self.bank.managername = sdatas.val().managername ?
                            sdatas.val().managername :
                            "";
                        self.bank.manageremail = sdatas.val().manageremail ?
                            sdatas.val().manageremail :
                            "";
                        self.bank.managerphone = sdatas.val().managerphone ?
                            sdatas.val().managerphone :
                            "";
                        self.imagetype = sdatas.val().scomapyimagetype ?
                            sdatas.val().scomapyimagetype :
                            "IMAGE";
                    });
                });

            sref
                .orderByChild("uid")
                .equalTo(self.getUid)
                .once("value", function (sdata) {
                    sdata.forEach(function (sdatas) {
                        self.applyData.push({
                            key: sdatas.key,
                            data: sdatas.val()
                        });
                    });
                });

            this.onPasteObj(this.$route.params.objs);

            this.useDeposit = 0;

            var sref2 = firebase.database().ref("coupon_master/");

            sref2
                .orderByChild("type")
                .equalTo("P")
                .once("value", function (sdata) {
                    sdata.forEach(function (sdatas) {
                        if (new Date() < new Date(sdatas.val().end)) {
                            self.pcouponlist.push({
                                key: sdatas.key,
                                name: sdatas.val().name,
                                id: sdatas.val().id,
                                qty: sdatas.val().c1,
                                data: sdatas.val()
                            });
                        }
                    });

                    sref2
                        .orderByChild("type")
                        .equalTo("S")
                        .once("value", function (sdata2) {
                            sdata2.forEach(function (sdatas2) {
                                if (new Date() < new Date(sdatas2.val().end)) {
                                    self.pcouponlist.push({
                                        key: sdatas2.key,
                                        name: sdatas2.val().name,
                                        id: sdatas2.val().id,
                                        qty: sdatas2.val().c1,
                                        data: sdatas2.val()
                                    });
                                }
                            });

                            var sref3 = firebase.database().ref("coupon_user/");

                            sref3
                                .orderByChild("uid")
                                .equalTo(self.getUserKey)
                                .once("value", function (sdata) {
                                    sdata.forEach(function (sdatas) {
                                        if (sdatas) {
                                            if (sdatas.val().flag == "Y") {
                                                self.ucouponlist.push({
                                                    key: sdatas.key,
                                                    cid: sdatas.val().cid,
                                                    data: sdatas.val()
                                                });
                                            } else {
                                                if (new Date() < new Date(sdatas.val().end)) {
                                                    self.couponlist.push({
                                                        key: sdatas.key,
                                                        cid: sdatas.val().cid,
                                                        name: sdatas.val().name,
                                                        data: sdatas.val()
                                                    });
                                                }
                                            }
                                        }
                                    });

                                    self.pcouponlist = self.pcouponlist.filter(item => {
                                        if (self.ucouponlist.find(sitem => sitem.cid == item.id)) {
                                            //skip//
                                        } else {
                                            return item;
                                        }
                                    });

                                    self.couponlist = self.couponlist.filter(item => {
                                        if (self.pcouponlist.find(sitem => sitem.id == item.cid)) {
                                            //skip//
                                        } else {
                                            return item;
                                        }
                                    });
                                });
                        });
                });

            if (this.$route.query.temp) {
                await this.onLoadDraft();
            }

            // Set Default Value
            await this.initValue();

            var sref = firebase.database().ref("user/");

            sref
                .orderByChild("email")
                .equalTo(this.getEmail)
                .once("value", function (sdata) {
                    sdata.forEach(function (sdatas) {
                        self.originalD = sdatas.val().deposit ? sdatas.val().deposit : 0;
                        self.deposit = sdatas.val().deposit ? sdatas.val().deposit : 0;
                    });
                });
        },
        async onLoadDraft() {
            var self = this;
            var db = firebase.firestore();
            var self = this;

            await db
                .collection("rank_apply_draft")
                .where("uid", "==", this.getUid)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        var _storetype =
                            doc.data().service_storetype == "네이버스토어팜" ?
                            "네이버스토어" :
                            doc.data().service_storetype;

                        if (doc.data()) {
                            self.service.md = doc.data().md ? doc.data().md : undefined;
                            self.buy = doc.data().buy;
                            self.text = doc.data().text;
                            self.photo = doc.data().photo;
                            self.video = doc.data().video;
                            self.zzim = doc.data().zzim;
                            self.qa = doc.data().qa;
                            self.reg = doc.data().reg ? doc.data().reg : null;
                            self.naver = doc.data().naver;
                            self.instagram = doc.data().instagram;
                            self.value1 = doc.data().value1;
                            self.value2 = doc.data().value2;
                            self.service.productno = doc.data().service_productno;
                            self.service.storeno = doc.data().service_storeno;
                            self.service.storename = doc.data().service_storename;
                            self.service.desc = doc.data().service_desc;
                            self.service.keyword = doc.data().service_keyword;
                            self.service.warning = doc.data().service_warning;
                            self.service.url = doc.data().service_url;
                            self.period = doc.data().period;
                            self.people = doc.data().people;
                            self.bank.companynoimg = doc.data().bank_companynoimg;
                            self.bank.companyemail = doc.data().bank_companyemail;
                            self.imagetype = doc.data().imagetype;
                            self.service.storetype = _storetype;
                            self.bank.companyname = doc.data().bank_companyname;
                            self.bank.ceoname = doc.data().bank_ceoname;
                            self.bank.managername = doc.data().bank_managername;
                            self.bank.manageremail = doc.data().bank_manageremail;
                            self.bank.managerphone = doc.data().bank_managerphone;
                            self.chkpolicy = doc.data().chkpolicy;
                            self.picdate = "";
                            self.posting.naver1 = doc.data().posting_naver1;
                            self.posting.naver2 = doc.data().posting_naver2;
                            self.posting.naver3 = doc.data().posting_naver3;
                            self.posting.naver4 = doc.data().posting_naver4;
                            self.posting.instagram2 = doc.data().posting_instagram2;
                            self.posting.instagram3 = doc.data().posting_instagram3;
                            self.posting.instagram4 = doc.data().posting_instagram4;
                            self.periods = [];

                            self.thumbnail = doc.data().thumbnail ?
                                doc.data().thumbnail :
                                null;
                            self.productImg = doc.data().productImg ?
                                doc.data().productImg :
                                null;

                            if (self.service.storetype == ShoppingMall.NAVER_PLACE) {
                                for (var i = 1; i < 6; i++) {
                                    self.periods.push({
                                        key: i.toString(),
                                        title: i.toString() + "개월"
                                    });
                                }
                            } else {
                                for (var i = 1; i < 60; i++) {
                                    if (self.buy % i == 0) {
                                        self.periods.push({
                                            key: i.toString(),
                                            title: i.toString() + "일"
                                        });
                                    }
                                }
                            }
                        }
                    });
                });
        },
        onPasteObj(obj) {
            if (obj == undefined) {
                //skip
            } else {
                var self = this;
                var sref = firebase.database().ref("rank_apply/" + obj.key + "/");

                sref.once("value", function (sdata) {
                    var _storetype =
                        sdata.val().storetype == "네이버스토어팜" ?
                        "네이버스토어" :
                        sdata.val().storetype;

                    self.buy = sdata.val().c1;
                    self.text = sdata.val().c2;
                    self.photo = sdata.val().c3;
                    self.zzim = sdata.val().c4;
                    self.qa = sdata.val().c5;
                    self.naver = sdata.val().c6 ? sdata.val().c6 : "0";
                    self.instagram = sdata.val().c7 ? sdata.val().c7 : "0";
                    self.video = sdata.val().c8 ? sdata.val().c8 : "0";
                    self.reg = sdata.val().c9 ? sdata.val().c9 : "0";

                    self.value1 = sdata.val().price;
                    self.value2 = sdata.val().dprice;
                    self.service.productno = parseInt(sdata.val().sn);
                    self.service.storeno = sdata.val().sshop;
                    self.service.storename = sdata.val().sname;
                    self.service.desc = sdata.val().desc;
                    self.service.keyword = sdata.val().keyword;
                    self.service.warning = sdata.val().warning;
                    self.service.url = sdata.val().url;
                    self.bank.companyemail = sdata.val().scomapyemail;
                    self.service.storetype = _storetype;
                    self.bank.companyname = sdata.val().scompanyname;
                    self.bank.ceoname = sdata.val().scompanyceo;
                    self.bank.managername = sdata.val().managername;
                    self.bank.manageremail = sdata.val().manageremail;
                    self.bank.managerphone = sdata.val().managerphone;

                    self.thumbnail = sdata.val().thumbnail ? sdata.val().thumbnail : null;
                    self.productImg = sdata.val().productImg ?
                        sdata.val().productImg :
                        null;

                    self.periods = [];
                    if (self.service.storetype == ShoppingMall.NAVER_PLACE) {
                        for (var i = 1; i < 6; i++) {
                            self.periods.push({
                                key: i.toString(),
                                title: i.toString() + "개월"
                            });
                        }
                    } else {
                        for (var i = 1; i < 60; i++) {
                            if (self.buy % i == 0) {
                                self.periods.push({
                                    key: i.toString(),
                                    title: i.toString() + "일"
                                });
                            }
                        }
                    }
                });
            }
        },
        onConfirmPolicy() {
            this.isCheckPolicy = true;
            this.dialogVisiable7 = false;
            this.step++;
        },
        onChangeMD(value) {
            this.service.md = value;
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
        },
        onChangePolicy(cheked, e) {
            this.chkpolicy = cheked;

            // if (!this.chkpolicy) {
            //   this.isCheckPolicy = false;
            //   var self = this;

            //   this.$confirm({
            //     title: "확인",
            //     content:
            //       "공정문구를 미표기하는 경우 향후 진행한 마켓(네이버쇼핑, 쿠팡 등)에서 불이익을 받을 수 있습니다. 그래도 공정문구를 미표기 하시겠습니까?",
            //     okText: "네",
            //     cancelText: "취소",
            //     onOk() {
            //       self.chkpolicy = false;
            //     },
            //     onCancel() {
            //       self.chkpolicy = true;
            //     }
            //   });
            // }
        },
        onSelectSCompany(e) {
            this.uncheck = e.target.checked;
        },
        saveToFirebaseStorage(evt, items, idx) {
            var _key = new Date().getTime() + idx;
            var self = this;

            var storage = firebase.storage();
            var storageRef = firebase.storage().ref();

            var _name = items.name.replace(
                /[~`!#$%\^&*+=\-\[\]\\';,/{}()|\\":<>\?]/g,
                ""
            );

            var uploadTask = storageRef
                .child("data/" + this.getUid + "/" + _key + "/" + _name)
                .put(items);

            uploadTask.on(
                "state_changed",
                function (snapshot) {
                    var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log("Upload is paused");
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log("Upload is running");
                            break;
                    }
                },
                function (error) {
                    console.log(error);
                },
                function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        self.bank.companynoimg = downloadURL;
                    });
                }
            );
        },

        createFile(file, idx) {
            if (!file.type.match("image.*") && !file.type.match("pdf.*")) {
                showError({
                    notify: this.$vs.notify,
                    msg: "이미지 및 PDF 화일을 선택해주세요."
                });

                this.handleRemove(file);
                return;
            } else {
                // var img = new Image();
                var reader = new FileReader();
                var vm = this;
                if (file.type == "application/pdf") {
                    this.imagetype = "PDF";
                } else {
                    this.imagetype = "IMAGE";
                }

                reader.onload = function (e) {
                    // vm.image = e.target.result;
                    vm.saveToFirebaseStorage(e, file, idx);
                };
                reader.readAsDataURL(file);
            }
        },
        handleChange({
            file,
            fileList,
            event
        }) {
            this.createFile(file, 0);
        },
        beforeUpload(file) {
            this.fileList = [...this.fileList, file];
            return false;
        },
        handleRemove(file) {
            const index = this.fileList.indexOf(file);
            const newFileList = this.fileList.slice();
            newFileList.splice(index, 1);
            this.fileList = newFileList;
            this.bank.companynoimg = "";
        },

        handleChange2({
            file,
            fileList,
            event
        }) {
            this.createFile2(file, 0);
        },
        beforeUpload2(file) {
            this.fileList2 = [file];
            return false;
        },
        handleRemove2(file) {
            console.log("aa");
            // const index = this.fileList2.indexOf(file);
            // const newFileList = this.fileList2.slice();
            // newFileList.splice(index, 1);
            this.fileList2 = [];
            this.originalThumbnail = null;
            console.log(this.originalThumbnail);
        },

        createFile2(file, idx) {
            if (!file.type.match("image.*")) {
                showError({
                    notify: this.$vs.notify,
                    msg: "이미지 화일을 선택해주세요."
                });

                this.handleRemove2(file);
                return;
            } else {
                // var img = new Image();
                var reader = new FileReader();
                var vm = this;
                reader.onload = function (e) {
                    // vm.image = e.target.result;
                    vm.originalThumbnail = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },

        handleChange3({
            file,
            fileList,
            event
        }) {
            this.createFile3(file, 0);
        },
        beforeUpload3(file) {
            this.fileList3 = [file];
            return false;
        },
        handleRemove3(file) {
            // const index = this.fileList3.indexOf(file);
            // const newFileList = this.fileList3.slice();
            // newFileList.splice(index, 1);
            this.fileList3 = [];
            this.originalProductImg = null;
        },

        createFile3(file, idx) {
            if (!file.type.match("image.*")) {
                showError({
                    notify: this.$vs.notify,
                    msg: "이미지 화일을 선택해주세요."
                });

                this.handleRemove3(file);
                return;
            } else {
                // var img = new Image();
                var reader = new FileReader();
                var vm = this;
                reader.onload = function (e) {
                    // vm.image = e.target.result;
                    vm.originalProductImg = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },

        // onChangePickDate() {
        //   var _dummy = new Date(this.picdate);
        //   if (_dummy < new Date().addHours(17) && _dummy.getHours() > 17) {
        //     showSuccess({
        //       notify: this.$vs.notify,
        //       msg: "17시 이후 입금된 캠페인의 경우 당일 진행이 불가능합니다."
        //     });

        //     this.picdate = moment(new Date().addDays(1));
        //   }
        // },
        onValidateURL() {
            var self = this;
            this.$vs.loading();
            var _url_ = replaceAll(this.service.url, " ", "");
            this.$http
                .post(
                    "https://us-central1-storelink-fnc7-8207a.cloudfunctions.net/onByPassURL", {
                        url: _url_
                    }
                )
                .then(function (r) {
                    var _s1 = r.data.indexOf("<script>window.__PRELOADED_STATE__=");
                    var _e1 = r.data.indexOf("/script>", _s1 + 35);
                    var _test = r.data.substring(_s1 + 35, _e1 - 1);

                    var _response = JSON.parse(_test);
                    if (_response.smartStore && _response.product.A.id) {
                        self.service.companyno = self.service.companyno ?
                            self.service.companyno :
                            _response.smartStore.channel.identity;
                        self.service.productno = _response.product.A.id;
                        self.service.storeno = _response.smartStore.store.channelNo;
                        self.service.storename = _response.smartStore.store.displayName;
                        self.$vs.loading.close();
                        self.checkedURL = true;
                    } else if (
                        _response.product.A.channel &&
                        _response.product.A.id &&
                        self.service.storetype == ShoppingMall.NAVER_LIVE
                    ) {
                        self.service.companyno = self.service.companyno ?
                            self.service.companyno :
                            "";
                        self.service.productno = _response.product.A.id;
                        self.service.storeno = _response.product.A.channel.accountNo;
                        self.service.storename = _response.product.A.channel.channelName;
                        self.$vs.loading.close();
                        self.checkedURL = true;
                    } else if (_response.product.A.channel && _response.product.A.id) {
                        self.service.companyno = self.service.companyno ?
                            self.service.companyno :
                            _response.store.A.channel.identity;
                        self.service.productno = _response.product.A.id;
                        self.service.storeno = _response.store.A.channel.accountNo;
                        self.service.storename = _response.store.A.channel.channelName;
                        self.$vs.loading.close();
                        self.checkedURL = true;
                    } else {
                        showError({
                            notify: self.$vs.notify,
                            msg: "해당 URL 가져올수 없습니다. 우측 하단에 있는 메신저 창을 통해 URL을 알려주시면 검토해보도록 하겠습니다."
                        });

                        self.$vs.loading.close();
                    }
                });
        },
        onNextStep(val) {
            var _a =
                (parseInt(this.value1) +
                    parseInt(this.value2) +
                    parseInt(this.buypay)) *
                parseInt(this.buy);

            if (!this.onCheckStep(val)) {
                this.step++;
                window.scrollTo(0, 0);
            }
        },
        onPreviousStep() {
            this.step--;
            window.scrollTo(0, 0);
        },
        onCheckStep(val) {
            if (val == 0) {
                if (
                    this.value1 < 0 ||
                    this.value2 < 0 ||
                    this.value2.toString() == ""
                ) {
                    showError({
                        notify: this.$vs.notify,
                        msg: "금액은 마이너스가 혹은 공란이 될수 없습니다."
                    });

                    return true;
                } else if (
                    (this.value1 || this.service.storetype == ShoppingMall.NAVER_PLACE) &&
                    this.service.url &&
                    ((this.service.storetype == ShoppingMall.TMON &&
                            this.service.storeno &&
                            this.service.storename) ||
                        ((this.service.storetype == ShoppingMall.NAVER_STORE ||
                                this.service.storetype == ShoppingMall.NAVER_WINDOW ||
                                this.service.storetype == ShoppingMall.NAVER_LIVE ||
                                this.service.storetype == ShoppingMall.NAVER_CROWD) &&
                            this.service.productno &&
                            this.service.storeno &&
                            this.service.storename) ||
                        (this.service.storetype != ShoppingMall.TMON &&
                            this.service.storetype != ShoppingMall.NAVER_STORE &&
                            this.service.storetype != ShoppingMall.NAVER_WINDOW &&
                            this.service.storetype != ShoppingMall.NAVER_LIVE &&
                            this.service.storetype != ShoppingMall.NAVER_CROWD &&
                            this.service.storename))
                ) {
                    return false;
                } else {
                    showError({
                        notify: this.$vs.notify,
                        msg: "필수 항목이 누락 되었습니다."
                    });

                    return true;
                }
            } else if (val == 1) {
                if (this.buy == null) {
                    showError({
                        notify: this.$vs.notify,
                        msg: this.getStore().errorType1
                    });

                    return true;
                } else {
                    if (
                        parseInt(this.getPhoto) +
                        parseInt(this.getVideo) +
                        parseInt(this.getText) >
                        parseInt(this.buy)
                    ) {
                        showError({
                            notify: this.$vs.notify,
                            msg: this.getStore().errorType2
                        });

                        return true;
                    } else if (parseInt(this.getZzim) > parseInt(this.buy)) {
                        showError({
                            notify: this.$vs.notify,
                            msg: this.getStore().errorType3
                        });

                        return true;
                    } else if (parseInt(this.getQa) > parseInt(this.buy)) {
                        showError({
                            notify: this.$vs.notify,
                            msg: this.getStore().errorType4
                        });

                        return true;
                    } else if (parseInt(this.getNaver) > parseInt(this.buy)) {
                        showError({
                            notify: this.$vs.notify,
                            msg: "구매 갯수가 네이버블로그 포스팅 숫자보다 작으면 안됩니다."
                        });

                        return true;
                    } else if (parseInt(this.getInstagram) > parseInt(this.buy)) {
                        showError({
                            notify: this.$vs.notify,
                            msg: "구매 갯수가 인스타그램 포스팅 숫자보다 작으면 안됩니다."
                        });

                        return true;
                    } else if (
                        parseInt(this.getNaver) + parseInt(this.getInstagram) >
                        parseInt(this.buy) * 2
                    ) {
                        showError({
                            notify: this.$vs.notify,
                            msg: "구매 갯수가 포스팅 숫자 합 보다 작으면 안됩니다."
                        });

                        return true;
                    } else if (parseInt(this.buy) < this.getStore().limit) {
                        showError({
                            notify: this.$vs.notify,
                            msg: this.getStore().errorType5
                        });

                        return true;
                    } else {
                        this.periods = [];
                        if (this.service.storetype == ShoppingMall.NAVER_PLACE) {
                            for (var i = 1; i < 6; i++) {
                                this.periods.push({
                                    key: i.toString(),
                                    title: i.toString() + "개월"
                                });
                            }
                        } else {
                            for (var i = 1; i < 60; i++) {
                                if (this.buy % i == 0) {
                                    this.periods.push({
                                        key: i.toString(),
                                        title: i.toString() + "일"
                                    });
                                }
                            }
                        }

                        this.confirmMessage = "";

                        if (parseInt(this.getText) == 0 && parseInt(this.getPhoto) == 0) {
                            this.confirmMessage +=
                                "<p>구매미션만 선택하신 경우, 상품 리뷰는 남겨지지 않습니다.<br/>구매미션만 선택하신 게 맞으신가요?</p>";
                        }

                        if (
                            parseInt(this.getInstagram) + parseInt(this.getNaver) >
                            parseInt(this.buy) &&
                            parseInt(this.getNaver) != 0 &&
                            parseInt(this.getInstagram) != 0
                        ) {
                            this.confirmMessage +=
                                "<p>설정한 블로그 미션수 + 인스타그램 미션수가 구매수를 초과합니다.<br/>이 경우 초과되는 수만큼의 구매자들이 1번의 구매로 블로그 포스팅과 <br/>인스타그램 포스팅 2개를 동시에 진행하게 됩니다.</p>";
                        }

                        if (this.confirmMessage) {
                            this.dialogMsg = true;
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            } else if (val == 2) {
                if (this.people && this.picdate) {
                    return false;
                } else {
                    showError({
                        notify: this.$vs.notify,
                        msg: "필수 입력 값이 누락 되었습니다."
                    });

                    return true;
                }
            } else if (val == 3) {
                if (!this.checkCorporateRegistrationNumber(this.service.companyno)) {
                    showError({
                        notify: this.$vs.notify,
                        msg: "잘못된 사업자 번호입니다. 확인 부탁드리겠습니다."
                    });

                    return true;
                } else if (this.bank.ceoname == "") {
                    showError({
                        notify: this.$vs.notify,
                        msg: "대표자 명은 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.service.companyno == "" && this.uncheck == false) {
                    showError({
                        notify: this.$vs.notify,
                        msg: "사업자 번호는 필수입니다. 간이 사업자 인 경우 사업자번호 채크 제외를 클릭해주세요"
                    });
                    return true;
                } else if (this.bank.companyname == "") {
                    showError({
                        notify: this.$vs.notify,
                        msg: "사업자등록증 상 기업명은 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.bank.companynoimg == "") {
                    showError({
                        notify: this.$vs.notify,
                        msg: "사업자 등록증은  필수 항목 입니다."
                    });
                    return true;
                } else if (this.bank.companyemail == "") {
                    showError({
                        notify: this.$vs.notify,
                        msg: "세금계산서 받을 이메일 주소는 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.bank.managername == "") {
                    showError({
                        notify: this.$vs.notify,
                        msg: "업체 담당자명은 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.bank.managerphone == "") {
                    showError({
                        notify: this.$vs.notify,
                        msg: "업체 담당자 연락처는 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.bank.manageremail == "") {
                    showError({
                        notify: this.$vs.notify,
                        msg: "업체 담당자 이메일은 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.service.md == undefined) {
                    showError({
                        notify: this.$vs.notify,
                        msg: "영업 MD 선택은 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.thumbnail == null) {
                    showError({
                        notify: this.$vs.notify,
                        msg: "상품 썸네일 이미지는 필수 입력 값 입니다."
                    });
                    return true;
                } else if (this.productImg == null) {
                    showError({
                        notify: this.$vs.notify,
                        msg: "상품 상세 이미지는 필수 입력 값 입니다."
                    });
                    return true;
                } else {
                    this.confirmMessage =
                        "<p>전자 세금 계산서 발급 받을 사업자 번호 가 [<span style='color:blue;font-weight:bold'>" +
                        this.service.companyno +
                        "</span>] 맞으신가요?</p>";
                    this.dialogMsg = true;
                    return true;
                    // if (this.isCheckPolicy == false && this.chkpolicy) {
                    //   this.dialogVisiable7 = true;
                    //   return true;
                    // } else {
                    //   return false;
                    // }
                }
            } else if (val == 4) {
                if (this.eventcode) {
                    if (!this.eventCodeConfirm) {
                        showError({
                            notify: this.$vs.notify,
                            msg: "프로모션 코드가 적용이 되지 않았습니다. 미사용 코드는 지우시고 지원을 해주세요"
                        });
                    } else {
                        this.applyDetail(3);
                    }
                } else {
                    this.applyDetail(1);
                }
            }
        },
        disabledDate(current) {
            var _gap = getCurrentHour();

            _gap = getCurrentHour() < 17 ? -1 : 0;

            // Can not select days before today and today
            return current && current < moment(new Date().addDays(_gap)).endOf("day");
        },
        onAddStoreType() {
            this.storelist.push({
                value: "자사몰[" + this._tmp_store + "]",
                label: "자사몰[" + this._tmp_store + "]",
                missionTitle: {
                    c1: "구매체험단",
                    c2: "텍스트미션",
                    c3: "포토미션",
                    c4: "찜하기",
                    c5: "Q&A"
                },
                errorType1: "구매 수량은 필수 입력 값 입니다.",
                errorType2: "구매 갯수가 포토 리뷰 + 텍스트리뷰 숫자보다 작으면 안됩니다.",
                errorType3: "구매 갯수가 찜 숫자보다 작으면 안됩니다.",
                errorType4: "구매 갯수가 Q&A 숫자보다 작으면 안됩니다.",
                errorType5: "최소 구매 갯수는 10개 입니다",
                limit: 10
            });
            this.service.storetype = "자사몰[" + this._tmp_store + "]";

            this.initValue();
            //인증을 사진으로 할수 있다고 해서 다시 풀음
            // self.status = false;
            this._tmp_store = "";
            this.visible1 = false;
        },
        onAddNewCard() {
            this.dialogVisible2 = true;
        },
        onSelectLicenseType(e) {
            this.level = e;
        },
        onConfirmMessage() {
            this.dialogMsg = false;
            this.step++;
        },
        applyDetailDraft() {
            var db = firebase.firestore();
            var self = this;

            db
                .collection("rank_apply_draft")
                .doc(this.getUid)
                .set({
                    uid: this.getUid,
                    buy: this.buy ? this.buy : null,
                    text: this.getText,
                    photo: this.getPhoto,
                    video: this.getVideo,
                    zzim: this.getZzim,
                    qa: this.getQa,
                    reg: this.getReg,
                    naver: this.getNaver,
                    instagram: this.getInstagram,
                    value1: this.value1,
                    value2: this.value2,
                    service_productno: this.service.productno,
                    service_storeno: this.service.storeno,
                    service_storename: this.service.storename,
                    service_desc: this.service.desc,
                    service_keyword: this.service.keyword,
                    service_warning: this.service.warning,
                    service_url: this.service.url,
                    period: this.period,
                    people: this.people,
                    bank_companynoimg: this.bank.companynoimg ?
                        this.bank.companynoimg :
                        "",
                    bank_companyemail: this.bank.companyemail ?
                        this.bank.companyemail :
                        "",
                    imagetype: this.imagetype,
                    service_storetype: this.service.storetype,
                    bank_companyname: this.bank.companyname ?
                        this.bank.companyname :
                        "",
                    bank_ceoname: this.bank.ceoname ? this.bank.ceoname : "",
                    bank_managername: this.bank.managername ?
                        this.bank.managername :
                        "",
                    bank_manageremail: this.bank.manageremail ?
                        this.bank.manageremail :
                        "",
                    bank_managerphone: this.bank.managerphone ?
                        this.bank.managerphone :
                        "",
                    chkpolicy: this.chkpolicy,
                    picdate: null,
                    posting_naver1: this.posting.naver1,
                    posting_naver2: this.posting.naver2,
                    posting_naver3: this.posting.naver3,
                    posting_naver4: this.posting.naver4,
                    posting_instagram2: this.posting.instagram2,
                    posting_instagram3: this.posting.instagram3,
                    posting_instagram4: this.posting.instagram4,
                    tp: this.getTotal1,
                    tp2: this.getTotal2,
                    original: parseInt(this.getTotal3.toString()),
                    originalvat: parseInt(
                        (this.getTotal3 + this.getTotal3 * 0.1).toString()
                    ),
                    p1: this.getTotalBuy,
                    p2: this.getTotalText,
                    p3: this.getTotalPhoto,
                    p4: this.getTotalZzim,
                    p5: this.getTotalQa,
                    p6: this.getTotalNaver,
                    p7: this.getTotalInstagram,
                    p8: this.getTotalVideo,
                    c1: parseInt(this.buy),
                    c2: parseInt(this.getText),
                    c3: parseInt(this.getPhoto),
                    c4: parseInt(this.getZzim),
                    c5: parseInt(this.getQa),
                    c6: parseInt(this.getNaver),
                    c7: parseInt(this.getInstagram),
                    c8: parseInt(this.getVideo),
                    c9: parseInt(this.getReg),
                    price: parseInt(this.value1),
                    dprice: parseInt(this.value2),
                    agency: this.getIsAgency == "T" ?
                        this.getUid :
                        this.getIsAgency == "3" ?
                        this.getUid :
                        this.getIsAgency == "2.5" ?
                        this.getUid :
                        this.getAgency ? this.getAgency : "-",
                    is3: this.getIsAgency == "3" ? "T" : "F",
                    storetype: this.service.storetype,
                    sname: this.service.storename,
                    desc: this.service.desc,
                    keyword: this.service.keyword,
                    warning: this.service.warning,
                    eventdttm: getCurrentTimeNew(),
                    md: this.service.md == undefined ? "" : this.service.md,
                    thumbnail: this.thumbnail,
                    productImg: this.productImg
                }, {
                    merge: true
                })
                .then(function () {
                    showSuccess({
                        notify: self.$vs.notify,
                        msg: "성공적으로 적용 되었습니다."
                    });
                });
        },
        applyDetail(type) {
            var d2 = new Date();
            var d = getCurrentTimeNew();
            var self = this;

            var newPostKey = firebase
                .database()
                .ref()
                .child("rank_apply/")
                .push().key;

            var _companyno = replaceAll(this.service.companyno, "-", "");
            var _sr = getSR(new Date(d2.getTime()));
            var _t = {
                uid: this.getUid,
                createdAt: d2.getTime(),
                eventdttm: d,
                reverseCreatedAt: -d2.getTime(),
                tp: this.getTotal1,
                tp2: this.getTotal2,
                original: parseInt(this.getTotal3.toString()),
                originalvat: parseInt(
                    (this.getTotal3 + this.getTotal3 * 0.1).toString()
                ),
                p1: this.getTotalBuy,
                p2: this.getTotalText,
                p3: this.getTotalPhoto,
                p4: this.getTotalZzim,
                p5: this.getTotalQa,
                p6: this.getTotalNaver,
                p7: this.getTotalInstagram,
                p8: this.getTotalVideo,
                p9: this.getTotalReg,
                c1: parseInt(this.buy),
                c2: parseInt(this.getText),
                c3: parseInt(this.getPhoto),
                c4: parseInt(this.getZzim),
                c5: parseInt(this.getQa),
                c6: parseInt(this.getNaver),
                c7: parseInt(this.getInstagram),
                c8: parseInt(this.getVideo),
                c9: parseInt(this.getReg),
                cid: "",
                price: parseInt(this.value1),
                dprice: parseInt(this.value2),
                sn: replaceAll(this.service.productno.toString(), " ", ""),
                sshop: this.service.storeno,
                sname: this.service.storename,
                desc: this.service.desc,
                keyword: this.service.keyword,
                warning: this.service.warning,
                email: this.getEmail,
                phone: this.getPhone,
                name: this.getName,
                status: type == 3 ? "E" : "A",
                url: this.service.url,
                period: this.period.toString(),
                periodn: this.people.toString(),
                scompanyno: _companyno,
                preview: null,
                scompayimage: this.bank.companynoimg ? this.bank.companynoimg : "",
                scomapyemail: this.bank.companyemail,
                scomapyimagetype: this.imagetype,
                applytype: type,
                storetype: this.service.storetype,
                sn2: _companyno,
                scompanyname: this.bank.companyname,
                scompanyceo: this.bank.ceoname,
                managername: this.bank.managername,
                manageremail: this.bank.manageremail,
                managerphone: this.bank.managerphone,
                usdollar: type == 4 ? this.target.total : 0,
                order: _sr,
                chkpolicy: this.chkpolicy,
                kind: type == 3 ? "E" : "A",
                bmgr: this.service.md == "99999" ? "" : this.service.md,
                deposit: this.useDeposit,
                couponQty: this.couponQty,
                couponPay: this.getCouponPay,
                couponType: this.eventcode ? "P" : "C",
                couponId: this.eventcode ?
                    this.eventcode :
                    this.couponMaster ? this.couponMaster : "",
                agency: this.getIsAgency == "T" ?
                    this.getUid :
                    this.getIsAgency == "3" ?
                    this.getUid :
                    this.getIsAgency == "2.5" ? this.getUid : this.getIsAgency,
                is3: this.getIsAgency == "3" ? "T" : "F",
                starttime: getCurrentTime2(new Date(this.picdate)),
                naver1: this.posting.naver1,
                naver2: this.posting.naver2,
                naver3: this.posting.naver3,
                naver4: this.posting.naver4,
                instagram2: this.posting.instagram2,
                instagram3: this.posting.instagram3,
                instagram4: this.posting.instagram4,
                buypay: this.buypay,
                textpay: this.textpay,
                photopay: this.photopay,
                videopay: this.videopay,
                zzimpay: this.zzimpay,
                qapay: this.qapay,
                regpay: this.regpay,
                naverpay: this.naverpay,
                instagrampay: this.instagrampay,
                thumbnail: this.thumbnail,
                productImg: this.productImg
            };

            var _bmgr = {
                name: "",
                value: ""
            };

            if (this.service.md) {
                this.mdlist.forEach(item => {
                    if (item) {
                        if (self.service.md == item.value) {
                            _bmgr = item;
                        }
                    }
                });
            }

            firebase
                .database()
                .ref("rank_apply/" + newPostKey)
                .set(_t)
                .then(function (result) {
                    self.confirmApply(newPostKey, _t, _bmgr["name"], _sr);
                });
        },
        applyError() {
            showError({
                notify: this.$vs.notify,
                msg: "최종 금액이 계산되지 않았습니다."
            });
        },
        apply(type) {
            this.validate();

            if (this.service.url == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "URL 은 필수 항목 입니다."
                });
            } else if (
                this.service.storeno == "" &&
                this.service.storetype == ShoppingMall.TMON
            ) {
                showError({
                    notify: this.$vs.notify,
                    msg: "상점 No. 는 필수 입력 란입니다."
                });
            } else if (this.period == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "진행기간은 필수 항목 입니다."
                });
            } else if (this.bank.ceoname == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "대표자 명은 필수 항목 입니다."
                });
            } else if (this.service.companyno == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "사업자 번호는 필수 항목 입니다."
                });
            } else if (this.bank.companyname == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "스토어명 은  필수 항목 입니다."
                });
            } else if (this.bank.companynoimg == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "사업자 등록증은  필수 항목 입니다."
                });
            } else if (this.bank.companyemail == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "세금 계산서 받을 이메일 주소는 필수 항목 입니다."
                });
            } else if (this.bank.managername == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "담당자 이름은 필수 항목 입니다."
                });
            } else if (this.bank.manageremail == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "담당자 이메일은 필수 항목 입니다."
                });
            } else if (this.bank.managerphone == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "담당자 전화번호는 필수 항목 입니다."
                });
            } else if (
                this.service.storename == "" &&
                (this.service.storetype == ShoppingMall.NAVER_STORE ||
                    this.service.storetype == ShoppingMall.NAVER_WINDOW ||
                    this.service.storetype == ShoppingMall.NAVER_LIVE ||
                    this.service.storetype == ShoppingMall.NAVER_CROWD)
            ) {
                showError({
                    notify: this.$vs.notify,
                    msg: "URL 인증을 통해 상점명을 입력해주세요"
                });
            } else if (this.service.storename == "") {
                showError({
                    notify: this.$vs.notify,
                    msg: "상점명은 필수 항목입니다"
                });
            } else if (this.service.md == undefined) {
                showError({
                    notify: this.$vs.notify,
                    msg: "담당 MD를 선택해주세요. 없으신 경우 없음을 반드시 선택 부탁드립니다."
                });
            } else {
                // this.genImage(type);
                this.applyDetail(type);
            }
        },
        confirmApply(key, obj, _bmgr, _sr) {
            var self = this;
            this.dialogVisiable = false;
            this.$vs.loading();

            if (this.useDeposit) {
                this.onSaveData(_sr);
            }
            this.useDeposit = this.useDeposit == "" ? "0" : this.useDeposit;

            this.$http
                .post(
                    "https://us-central1-store-link-49fa1.cloudfunctions.net/invokeMsg6", {
                        name: self.bank.managername,
                        storetype: self.service.storetype,
                        keyword: self.service.desc,
                        original: parseInt(
                            self.getTotal3 + self.getTotal3 * 0.1
                        ).toLocaleString(),
                        coupon: self.getCouponBuyPay,
                        deposit: parseInt(self.useDeposit).toLocaleString(),
                        total: parseInt(self.getTotal2).toLocaleString(),

                        phone: self.bank.managerphone.replace(/-/g, "").substr(1)
                    }
                )
                .then(function (x) {
                    //remove temp data and then location reload.
                    self.onUpdateCoupon(key, _sr);
                });

            if (this.getAlt1) {
                this.$http
                    .post(
                        "https://us-central1-store-link-49fa1.cloudfunctions.net/invokeMsg6", {
                            name: self.bank.managername,
                            storetype: self.service.storetype,
                            keyword: self.service.desc,
                            original: parseInt(
                                self.getTotal3 + self.getTotal3 * 0.1
                            ).toLocaleString(),
                            coupon: self.getCouponBuyPay,
                            deposit: parseInt(self.useDeposit).toLocaleString(),
                            total: parseInt(self.getTotal2).toLocaleString(),

                            phone: self.getAlt1.replace(/-/g, "").substr(1)
                        }
                    )
                    .then(function (x) {});
            }
            if (this.getAlt2) {
                this.$http
                    .post(
                        "https://us-central1-store-link-49fa1.cloudfunctions.net/invokeMsg6", {
                            name: self.bank.managername,
                            storetype: self.service.storetype,
                            keyword: self.service.desc,
                            original: parseInt(
                                self.getTotal3 + self.getTotal3 * 0.1
                            ).toLocaleString(),
                            coupon: self.getCouponBuyPay,
                            deposit: parseInt(self.useDeposit).toLocaleString(),
                            total: parseInt(self.getTotal2).toLocaleString(),

                            phone: self.getAlt2.replace(/-/g, "").substr(1)
                        }
                    )
                    .then(function (x) {});
            }

            if (_bmgr) {
                this.$http
                    .post(
                        "https://us-central1-store-link-49fa1.cloudfunctions.net/invokeMsg10_1", {
                            sr: obj.order,
                            storetype: obj.storetype,
                            storename: obj.sname,
                            manager: obj.managername,
                            bmgr: _bmgr,
                            keyword: obj.keyword.substr(0, 10) + "..",
                            total: obj.tp2.toLocaleString() + "원"
                        }
                    )
                    .then(function (x) {
                        self.$vs.loading.close();
                        //reroute

                        showSuccess({
                            notify: self.$vs.notify,
                            msg: "성공적으로 지원 되었습니다."
                        });
                        setTimeout(function () {
                            self.step = 7;
                        }, 1000);
                    });
            } else {
                this.$http
                    .post(
                        "https://us-central1-store-link-49fa1.cloudfunctions.net/invokeMsg10", {
                            sr: obj.order,
                            storetype: obj.storetype,
                            storename: obj.sname,
                            manager: obj.managername,
                            keyword: obj.keyword.substr(0, 10) + "..",
                            total: obj.tp2.toLocaleString() + "원"
                        }
                    )
                    .then(function (x) {
                        self.$vs.loading.close();
                        //reroute

                        showSuccess({
                            notify: self.$vs.notify,
                            msg: "성공적으로 지원 되었습니다."
                        });
                        setTimeout(function () {
                            self.step = 7;
                        }, 1000);
                    });
            }
        },
        onUpdateCoupon(key, _sr) {
            var d2 = new Date();
            var d = getCurrentTimeNew();
            var self = this;

            if (this.couponQty > 0) {
                if (this.eventcode != "") {
                    var newPostKey = firebase
                        .database()
                        .ref()
                        .child("coupon_user/")
                        .push().key;

                    var _c = this.pcouponlist.find(item => item.id == this.eventcode);

                    if (_c) {
                        firebase
                            .database()
                            .ref("coupon_user/" + newPostKey)
                            .set({
                                c1: "1",
                                cid: self.eventcode,
                                createdAt: d2.getTime(),
                                date: d,
                                eventdttm: d,
                                reverseCreatedAt: -d2.getTime(),
                                name: _c.data.name,
                                flag: "Y",
                                start: _c.data.start,
                                end: _c.data.end,
                                uid: self.getUserKey,
                                rid: key,
                                orderId: _sr
                            })
                            .then(function (result) {
                                self.onUpdateCouponMaster(self.eventcode.substr(1));
                            })
                            .catch(error => {});
                    }
                } else if (this.coupon != "") {
                    //update

                    var uref = firebase.database().ref("coupon_user/" + this.coupon);

                    var upData = {
                        flag: "Y",
                        date: getCurrentTimeNew(),
                        rid: key
                    };

                    uref
                        .update(upData)
                        .then(function () {
                            self.onUpdateCouponMaster(self.couponMaster.substr(1));
                        })
                        .catch(function (error) {
                            alert("Data could not be saved." + error);
                        });
                }
            }
        },
        onUpdateCouponMaster(key) {
            var sref = firebase.database().ref("coupon_master/" + key + "/");
            sref.once("value", function (sdata) {
                var uref = firebase.database().ref("coupon_master/" + key + "/");

                var _current = sdata.val().ccount;

                var upData = {
                    ccount: _current + 1
                };
                uref
                    .update(upData)
                    .then(function () {})
                    .catch(function (error) {
                        alert("Data could not be saved." + error);
                    });
            });
        },
        onSaveData(_sr) {
            var d2 = new Date();
            var d = getCurrentTimeNew();
            var self = this;

            var newPostKey = firebase
                .database()
                .ref()
                .child("deposit/")
                .push().key;

            firebase
                .database()
                .ref("deposit/" + newPostKey)
                .set({
                    uid: self.getUid,
                    eventuser: self.getEmail,
                    createdAt: d2.getTime(),
                    eventdttm: d,
                    reverseCreatedAt: -d2.getTime(),
                    desc: "캠페인 신청",
                    type: "remove",
                    upoint: self.useDeposit,
                    tpoint: self.deposit,
                    orderId: _sr
                })
                .then(function (result) {
                    self.setUserInfo(self.deposit);
                })
                .catch(error => {});
        },
        setUserInfo(deposit) {
            if (this.getUserKey) {
                var uref = firebase.database().ref("user/" + this.getUserKey);

                var upData = {
                    deposit: deposit
                };

                uref
                    .update(upData)
                    .then(function () {})
                    .catch(function (error) {
                        alert("Data could not be saved." + error);
                    });
            }
        },
        change({
            coordinates,
            canvas
        }) {
            console.log(coordinates, canvas);
        },
        handlePeriodClick(e) {
            this.period = parseInt(e.key);
            this.people = parseInt(this.buy) / this.period;
        },
        checkCorporateRegistrationNumber(value) {
            if (this.uncheck == false) {
                var checkID = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5, 1);
                var tmpBizID,
                    i,
                    chkSum = 0,
                    c2,
                    remander;
                var result;

                var bizID = value.replace(/-/gi, "");

                for (i = 0; i <= 7; i++) {
                    chkSum += checkID[i] * bizID.charAt(i);
                }

                c2 = "0" + checkID[8] * bizID.charAt(8);
                c2 = c2.substring(c2.length - 2, c2.length);
                chkSum += Math.floor(c2.charAt(0)) + Math.floor(c2.charAt(1));
                remander = (10 - chkSum % 10) % 10;

                if (Math.floor(bizID.charAt(9)) == remander) {
                    result = true; // OK!
                } else {
                    result = false;
                }

                return result;
            } else {
                return true;
            }
        }
    }
};
</script>

<style>
.step2 .right input {
    text-align: right !important;
}

.step4 .ant-btn,
.step4 .ant-upload,
.step4 .ant-select-selection,
.step5 .ant-select-selection,
.step2 .ant-select-selection {
    height: 40px;
    width: 100%;
}

.step5 .ant-btn,
.step2 .ant-btn {
    height: 40px;
}

.step2 .ant-select-selection__placeholder,
.step2 .ant-select-selection-selected-value,
.step4 .ant-select-selection__placeholder,
.step4 .ant-select-selection-selected-value,
.step5 .ant-select-selection__placeholder,
.step5 .ant-select-selection-selected-value {
    height: 40px;
    padding-top: 5px;
}

.s-target-b {
    max-width: 394px;
    width: 100%;
    height: 73px;
    background-color: #ffffff;
    margin-top: 60px;
}

.s-target-b2 {
    max-width: 394px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #0264fb;
    background-color: #e6f0ff;
    display: table;
    margin: 0px auto;
}

.s-target-b3 {
    max-width: 394px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #0264fb;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.88px;
    color: #ffffff;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}

.s-target-b4 {
    max-width: 394px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #7d7d7d;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.88px;
    color: #ffffff;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}

.s-target-b5 {
    background-image: url("../../../src/assets/images/bill_big.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 440px;
}

.s-target-b6 {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #dbdbdb;
    background-color: #dcdcdc;
    padding-left: 10px;
    padding-top: 10px;
}

.s-target-b7 {
    background-image: url("../../../src/assets/images/bill_big.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 626px;
}

.s-target-b8 {
    width: 100%;
    height: 179px;
    border-radius: 5px;
    background-color: #ffffff;
    border-bottom: 14px solid #0264fb;
}

.s-target-b9 {
    max-width: 394px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #f2da00;
    background-color: #fffbd8;
    display: table;
    margin: 0px auto;
    padding-bottom: 10px;
}

.s-target-r1 {
    width: 20px;
    height: 20px;
    background-color: #0264fb;
    border-radius: 50%;
    color: white;
    font-size: 11px;
    padding-top: 2px;
    margin: 2px;
}

.s-target-r2 {
    width: 20px;
    height: 20px;
    background-color: #bfbfbf;
    border-radius: 50%;
    color: white;
    font-size: 11px;
    padding-top: 2px;
    margin: 2px;
}

.s-target-font1 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #0264fb;
}

.s-target-font2 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.88px;
    color: #000000;
}

.s-target-font3 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.38px;
    color: #000000;
}

.s-target-font4 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.58px;
    color: #7d7d7d;
}

.s-target-font5 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
    padding-bottom: 8px;
    border-bottom: 2px solid black;
}

.s-target-font6 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.5px;
    color: #e02020;
}

.s-target-font7 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
}

.s-target-font8 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: -0.5px;
    color: #7d7d7d;
}

.s-target-font8-1 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    color: #7d7d7d;
}

.s-target-font9 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #7d7d7d;
}

.s-target-font10 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.1;
    letter-spacing: -0.83px;
    text-align: right;
    color: #e02020;
}

.s-target-font11 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: -0.5px;
    color: #e02020;
}

.s-target-font12 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.5px;
    color: #e02020;
}

.s-target-font13 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -1.13px;
    text-align: center;
    color: #0264fb;
}

.s-target-font14 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.88px;
    color: #7d7d7d;
}

.s-target-font15 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.88px;
    color: #e02020;
}

.s-target-font16 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.58px;
    color: #7d7d7d;
}

.s-target-font17 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
}

.s-target-font18 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: -0.58px;
    color: #0264fb;
}

.s-target-font19 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: -0.5px;
    color: #000000;
}

.s-target-font20 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: -0.5px;
    color: #7d7d7d;
}

.s-target-font21 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.11px;
    color: #0264fb;
    text-align: center;
}

.s-target-font22 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.67px;
    text-align: center;
    color: #444444;
}

.s-target-font23 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: -0.88px;
    color: #7d7d7d;
}

.s-target-font23>span {
    color: #0264fb;
    font-weight: bold;
    text-decoration: underline;
}

.target .input .ant-input,
.target .input .ant-input-number,
.target .ant-dropdown-trigger {
    height: 40px;
}

.target .input .ant-input-number {
    padding-top: 2px;
}

.step5 .ant-col.offset {
    margin-top: -20px;
}

.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.cropper {
    height: 335px;
    width: 335px;
    background: #ddd;
    margin: 0 auto;
}

.cropper2 {
    height: 723px;
    width: 701px;
    background: #ddd;
    margin: 0 auto;
}

.target .ant-input-number {
    padding-right: 10px;
}

.smalllogo {
    width: 26px;
    height: 26px;
    margin-top: -4px;
    margin-right: 5px;
}
</style>

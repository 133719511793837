<template>
<div>
    <div class="s-target-b" v-if="step==1">
        <div style="display:flex;float:left;margin-left:20px;margin-top:20px">
            <div class="s-target-r1" style="  padding-left: 8px;">1</div>
            <div class="s-target-font1">지원신청</div>

        </div>
        <div style="display:flex;float:right;margin-right:20px;margin-top:20px">
            <div class="s-target-r2" style="  padding-left: 7px;">2</div>
            <div class="s-target-r2" style="  padding-left: 7px;">3</div>
            <div class="s-target-r2" style="  padding-left: 7px;">4</div>
            <div class="s-target-r2" style="  padding-left: 7px;">5</div>
        </div>

    </div>
    <div style="border:1px solid #0264fb;width:20%;margin-top:-20px;margin-left:20px;" v-if="step==1"></div>
    <div class="s-target-b" v-if="step==2">
        <div style="display:flex;float:left;margin-left:20px;margin-top:20px">
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 7px;">2</div>
            <div class="s-target-font1">상위노출 진행정보 입력</div>

        </div>
        <div style="display:flex;float:right;margin-right:20px;margin-top:20px">
            <div class="s-target-r2" style="  padding-left: 7px;">3</div>
            <div class="s-target-r2" style="  padding-left: 7px;">4</div>
            <div class="s-target-r2" style="  padding-left: 7px;">5</div>
        </div>
    </div>
    <div style="border:1px solid #0264fb;width:40%;margin-top:-20px;margin-left:20px;" v-if="step==2"></div>
    <div class="s-target-b" v-if="step==3">
        <div style="display:flex;float:left;margin-left:20px;margin-top:20px">
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 7px;">3</div>
            <div class="s-target-font1">시작일</div>

        </div>
        <div style="display:flex;float:right;margin-right:20px;margin-top:20px">
            <div class="s-target-r2" style="  padding-left: 7px;">4</div>
            <div class="s-target-r2" style="  padding-left: 7px;">5</div>
        </div>
    </div>
    <div style="border:1px solid #0264fb;width:60%;margin-top:-20px;margin-left:20px;" v-if="step==3"></div>
    <div class="s-target-b" v-if="step==4">
        <div style="display:flex;float:left;margin-left:20px;margin-top:20px">
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 6px;">4</div>
            <div class="s-target-font1">부가정보 입력</div>

        </div>
        <div style="display:flex;float:right;margin-right:20px;margin-top:20px">
            <div class="s-target-r2" style="  padding-left: 7px;">5</div>
        </div>
    </div>
    <div style="border:1px solid #0264fb;width:80%;margin-top:-20px;margin-left:20px;" v-if="step==4"></div>
    <div class="s-target-b" v-if="step==5">
        <div style="display:flex;float:left;margin-left:20px;margin-top:20px">
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 5px;"><i class="fal fa-check"></i></div>
            <div class="s-target-r1" style="  padding-left: 6px;">5</div>
            <div class="s-target-font1">결제</div>

        </div>
    </div>
    <div style="border:1px solid #0264fb;width:90%;margin-top:-20px;margin-left:20px;" v-if="step==5"></div>
</div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style>
</style>

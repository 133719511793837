const STORELINKVER = "3.5.3";
import store from "../../store/store";

export const SCAN_PRINT_MODE = "PRINT";
export const SCAN_NORMAL_MODE = "NORMAL";

export const ShoppingMall = Object.freeze({
  BAEMIN: "배달의민족",
  NAVER_PLACE: "네이버플레이스",
  NAVER_STORE: "네이버스토어",
  NAVER_WINDOW: "네이버뷰티윈도우",
  NAVER_CROWD: "네이버스토어(예약형)",
  NAVER_LIVE: "네이버쇼핑LIVE",
  COUPANG: "쿠팡",
  CJ: "CJ몰",
  ELEVEN: "11번가",
  GMARKET: "G마켓",
  AUCTION: "옥션",
  GSSHOP: "GS SHOP",
  TMON: "티몬",
  WEMAFE: "위메프",
  WADIZ: "와디즈",
  KAKAO: "카카오메이커스",
  TALK: "톡스토어",
  OHHOUSE: "오늘의집"
});

export const SEARCH_TYPE = Object.freeze({
  RELATETAB: "연관검색어",
  AUTOCOMPLEATETAB: "자동완성검색어",
  VIEWTAB: "뷰탭 연관검색어",
  SHOPPINGTAB: "쇼핑탭연관검색어",
  TOP10TAB: "카테고리TOP10",
  TODAYFAVTAB: "오늘의인기검색어",
  WEEKFAVTAB: "주간인기검색어",
  MONTHFAVTAB: "월간인기검색어",
  COMPETITIVETAB: "경쟁제품테크",
  MYKEYWORD: "나의키워드",
  PASS: "PASS",
  DIRTY: "DIRTY",
  SIMPLE: "SIMPLE",
  COMPLEX: "COMPLEX",
  EMPTY: "EMPTY"
});

export const SIDEMENU_TYPE = Object.freeze({
  KEYWORD: "키워드로찾기",
  CATEGORY: "카테고리로찾기",
  COMPETITIVE: "경쟁제품찾기",
  MYKEYWORD: "나의키워드"
});

function Android() {
  return navigator.userAgent.match(/Android/i);
}

function BlackBerry() {
  return navigator.userAgent.match(/BlackBerry/i);
}

function iOS() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i);
}

function Opera() {
  return navigator.userAgent.match(/Opera Mini/i);
}

function Windows() {
  return (
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/WPDesktop/i)
  );
}

function IE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    return true;
  } else {
    return false;
  }
}

export function isIE() {
  return IE();
}

export function isMobile() {
  var _flag = Android() || BlackBerry() || iOS() || Opera() || Windows();
  _flag = _flag == null ? false : _flag;

  //Force resize
  if (window.innerWidth < 1024) {
    _flag = true;
  }

  return _flag;
}

export function extractCategory(item) {
  var _r = item.category1Name;
  _r += item.category2Name ? ">" + item.category2Name : "";
  _r += item.category3Name ? ">" + item.category3Name : "";
  _r += item.category4Name ? ">" + item.category4Name : "";

  return _r;
}

export function getVersion() {
  return STORELINKVER;
}

export function isNaN(value) {
  Number.isNaN(Number(value));
}

export function chkLicense(payload) {
  var _currCount = store.state.AppActiveUser.currCount
    ? store.state.AppActiveUser.currCount
    : store.state.AppActiveUser.level;

  if (
    store.state.AppActiveUser.level < _currCount ||
    store.state.AppActiveUser.remain < 0
  ) {
    if (payload) {
      payload.notify({
        title: "라이센스",
        text:
          "라이센스가 만료되었거나 관심제품 수량이 현재 등급에 맞지 않습니다.",
        color: "danger",
        iconPack: "feather",
        icon: "icon-message-square"
      });
    }

    return false;
  } else {
    return true;
  }
}

export function chkLicenseForDelete(payload) {
  var _currCount = store.state.AppActiveUser.currCount
    ? store.state.AppActiveUser.currCount
    : store.state.AppActiveUser.level;
  if (
    store.state.AppActiveUser.level + 1 < _currCount ||
    store.state.AppActiveUser.remain < 0
  ) {
    if (payload) {
      payload.notify({
        title: "라이센스",
        text:
          "라이센스가 만료되었거나 관심제품 수량이 현재 등급에 맞지 않습니다.",
        color: "danger",
        iconPack: "feather",
        icon: "icon-message-square"
      });
    }

    return false;
  } else {
    return true;
  }
}

export function showError(payload) {
  payload.notify({
    title: "에러",
    text: payload.msg,
    color: "#f44336",
    iconPack: "feather",
    icon: "icon-alert-circle"
  });
}

export function showSuccess(payload) {
  payload.notify({
    color: "#0264fb",
    iconPack: "feather",
    icon: "icon-check",
    title: "메세지",
    text: payload.msg
  });
}

export function getCurrentTime2(val) {
  var t = "";

  var t1 = new Date(val);

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    yyyy +
    "/" +
    (mm[1] ? mm : "0" + mm[0]) +
    "/" +
    (dd[1] ? dd : "0" + dd[0]) +
    " " +
    hh +
    ":" +
    min +
    ":" +
    ss;

  return t;
}

export function getCurrentHour() {
  var t1 = new Date();
  return t1.getHours();
}

export function formatNumber(value) {
  if (value) {
    value += "";
    const list = value.split(".");
    const prefix = list[0].charAt(0) === "-" ? "-" : "";
    let num = prefix ? list[0].slice(1) : list[0];
    let result = "";
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
  }
}

export function getSR(t1) {
  var t = "";

  var yyyy = t1
    .getFullYear()
    .toString()
    .substr(2, 2);
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    "SR-" +
    yyyy +
    (mm[1] ? mm : "0" + mm[0]) +
    (dd[1] ? dd : "0" + dd[0]) +
    "." +
    hh +
    min +
    ss;

  return t;
}

export function getLicense() {
  var _level = parseInt(store.state.AppActiveUser.limit);

  if (store.state.AppActiveUser.remain < 0) {
    return 0;
  } else if (_level > 300) {
    return 3;
  } else if (_level > 100) {
    return 2;
  } else if (_level > 5) {
    return 1;
  } else {
    return 0;
  }
}

export function getCurrentTime(t1) {
  var t = "";

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    yyyy +
    "/" +
    (mm[1] ? mm : "0" + mm[0]) +
    "/" +
    (dd[1] ? dd : "0" + dd[0]) +
    " " +
    hh +
    ":" +
    min +
    ":" +
    ss;

  return t;
}

export function dateDiffInDays(b) {
  var _MS_PER_DAY = 1000 * 60 * 60 * 24;
  var a = new Date();
  // Discard the time and time-zone information.
  var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function getCurrentDate(val) {
  var t = "";

  var t1 = val;

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    yyyy + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + (dd[1] ? dd : "0" + dd[0]);

  return t;
}

export function getCurrentDate2(val) {
  var t = "";

  var t1 = val;

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    "'" +
    yyyy.substr(2) +
    "/" +
    (mm[1] ? mm : "0" + mm[0]) +
    "/" +
    (dd[1] ? dd : "0" + dd[0]) +
    " " +
    hh;

  return t;
}

export function getHex(str) {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}

export function getCurrentTimeNew() {
  var t = "";

  var t1 = new Date();

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    yyyy +
    "/" +
    (mm[1] ? mm : "0" + mm[0]) +
    "/" +
    (dd[1] ? dd : "0" + dd[0]) +
    " " +
    hh +
    ":" +
    min +
    ":" +
    ss;

  return t;
}

export function getCurrentDate3(val) {
  var t = "";

  var t1 = val;

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t = yyyy + (mm[1] ? mm : "0" + mm[0]) + (dd[1] ? dd : "0" + dd[0]);

  return t;
}

export function getCurrentDate4() {
  var t = "";

  var t1 = new Date();

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();

  t =
    "(" +
    yyyy.substr(2) +
    "." +
    (mm[1] ? mm : "0" + mm[0]) +
    "." +
    (dd[1] ? dd : "0" + dd[0]) +
    ")";

  return t;
}

export function getCurrentDate3From(val) {
  var t = "";

  var t1 = val;

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    yyyy +
    "/" +
    (mm[1] ? mm : "0" + mm[0]) +
    "/" +
    (dd[1] ? dd : "0" + dd[0]) +
    " 00:00:00";

  return t;
}

export function getCurrentDate3To(val) {
  var t = "";

  var t1 = val;

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    yyyy +
    "/" +
    (mm[1] ? mm : "0" + mm[0]) +
    "/" +
    (dd[1] ? dd : "0" + dd[0]) +
    " 23:59:59";

  return t;
}

export function replaceAll(str, searchStr, replaceStr) {
  return str.split(searchStr).join(replaceStr);
}
export function getCurrentDay(val) {
  var t = "";

  var t1 = val;

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();

  t =
    yyyy + "." + (mm[1] ? mm : "0" + mm[0]) + "." + (dd[1] ? dd : "0" + dd[0]);

  return t;
}

export function getToday() {
  var t = "";

  var t1 = new Date();

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();

  t =
    yyyy +
    ". " +
    (mm[1] ? mm : "0" + mm[0]) +
    ". " +
    (dd[1] ? dd : "0" + dd[0]) +
    " 00:00:00";

  return t;
}
